import React from "react";
import { X } from "lucide-react"; // Close icon
import "./CheckoutModal.css"; // Ensure you create a CSS file

const CheckoutModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="checkout-modal-overlay" onClick={onClose}>
      <div className="checkout-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="checkout-modal-close-btn" onClick={onClose}>
          <X size={24} />
        </button>
        <div className="checkout-modal-body">{children}</div>
      </div>
    </div>
  );
};

export default CheckoutModal;
