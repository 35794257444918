import React, { useState, useEffect } from 'react';
import { ref, push, onValue, database } from '../firebase'; // Using Firebase
import { useAuth } from '../context/AuthContext'; // To get the logged-in user
import toast, { Toaster } from 'react-hot-toast';
import { PlusCircle, Calendar, CheckCircle, AlertTriangle } from 'lucide-react';
import './AdminTransaction.css';

const AdminTransaction = () => {
  const { currentUser } = useAuth(); // Get the current authenticated user
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [newTransaction, setNewTransaction] = useState({
    type: 'web',
    amount: '',
    description: '',
  });
  const [filters, setFilters] = useState({ date: '', month: '', year: '' });
  const [total, setTotal] = useState(0);

  // Formatter for currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
    }).format(amount);
  };

  // Fetch transactions from Firebase
  useEffect(() => {
    const transactionsRef = ref(database, 'transactions');
    onValue(transactionsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const formattedTransactions = Object.values(data);
        setTransactions(formattedTransactions);
      } else {
        setTransactions([]);
        setFilteredTransactions([]);
        setTotal(0);
      }
    });
  }, []);

  // Apply Filters and Calculate Total
  useEffect(() => {
    let filtered = transactions;

    if (filters.date) {
      filtered = filtered.filter((transaction) => {
        const transactionDate = new Date(transaction.date).toISOString().split('T')[0];
        return transactionDate === filters.date;
      });
    }

    if (filters.month) {
      filtered = filtered.filter((transaction) => {
        const transactionMonth = new Date(transaction.date).getMonth() + 1; // Months are 0-based
        return transactionMonth === parseInt(filters.month, 10);
      });
    }

    if (filters.year) {
      filtered = filtered.filter((transaction) => {
        const transactionYear = new Date(transaction.date).getFullYear();
        return transactionYear === parseInt(filters.year, 10);
      });
    }

    setFilteredTransactions(filtered);

    // Recalculate Total Balance for the filtered transactions
    const newTotal = filtered.reduce((acc, transaction) => acc + transaction.amount, 0);
    setTotal(newTotal);
  }, [transactions, filters]);

  // Handle New Transaction Submission
  const handleAddTransaction = async () => {
    if (!newTransaction.amount || !newTransaction.description) {
      toast.error('Please fill out all fields.', { icon: <AlertTriangle size={20} /> });
      return;
    }

    try {
      const transaction = {
        ...newTransaction,
        amount: parseFloat(newTransaction.amount),
        date: new Date().toISOString(),
        addedBy: currentUser?.displayName || currentUser?.email,
      };

      const transactionsRef = ref(database, 'transactions');
      await push(transactionsRef, transaction);

      toast.success('Transaction logged successfully!', { icon: <CheckCircle size={20} /> });
      setNewTransaction({ type: 'web', amount: '', description: '' });
    } catch (error) {
      console.error('Error adding transaction:', error);
      toast.error('Failed to log transaction.', { icon: <AlertTriangle size={20} /> });
    }
  };

  // Export Data to CSV
  const exportData = () => {
    const headers = ['Date,Time,Type,Amount,Description,Added By\n'];
    const rows = filteredTransactions.map((t) => {
      const date = new Date(t.date);
      return [
        date.toLocaleDateString(),
        date.toLocaleTimeString(),
        t.type,
        formatCurrency(t.amount),
        t.description,
        t.addedBy,
      ].join(',');
    });
    const csvContent = headers.concat(rows).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `transactions-${new Date().toISOString()}.csv`;
    link.click();
  };

  return (
    <div className="admin-transaction-container">
      <Toaster position="top-right" reverseOrder={false} />

      {/* Total Balance */}
      <div className="total-balance">
        <h3>Total Balance: {formatCurrency(total)}</h3>
      </div>

      {/* Filters */}
      <div className="filters">
        <input
          type="date"
          value={filters.date}
          onChange={(e) => setFilters({ ...filters, date: e.target.value })}
        />
        <select
          value={filters.month}
          onChange={(e) => setFilters({ ...filters, month: e.target.value })}
        >
          <option value="">Filter by Month</option>
          {[...Array(12)].map((_, i) => (
            <option key={i + 1} value={i + 1}>
              {new Date(0, i).toLocaleString('default', { month: 'long' })}
            </option>
          ))}
        </select>
        <select
          value={filters.year}
          onChange={(e) => setFilters({ ...filters, year: e.target.value })}
        >
          <option value="">Filter by Year</option>
          {[...Array(10)].map((_, i) => {
            const year = new Date().getFullYear() - i;
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </select>
        <button onClick={exportData}>
          <Calendar size={16} /> Export CSV
        </button>
      </div>

      {/* Add Transaction Form */}
      <div className="add-transaction-form">
        <h3>
          <PlusCircle size={20} /> Log New Transaction
        </h3>
        <select
          value={newTransaction.type}
          onChange={(e) => setNewTransaction({ ...newTransaction, type: e.target.value })}
        >
          <option value="web">Web</option>
          <option value="whatsapp">WhatsApp</option>
          <option value="shop">Shop</option>
        </select>
        <input
          type="number"
          placeholder="Amount"
          value={newTransaction.amount}
          onChange={(e) => setNewTransaction({ ...newTransaction, amount: e.target.value })}
        />
        <input
          type="text"
          placeholder="Description"
          value={newTransaction.description}
          onChange={(e) => setNewTransaction({ ...newTransaction, description: e.target.value })}
        />
        <button onClick={handleAddTransaction}>
          <PlusCircle size={16} /> Add Transaction
        </button>
      </div>

      {/* Transactions Table */}
      <div className="transactions-table">
        <div className="transactions-header">
          <p>Date</p>
          <p>Time</p>
          <p>Type</p>
          <p>Amount</p>
          <p>Description</p>
          <p>Added By</p>
        </div>
        {filteredTransactions.map((transaction, index) => {
          const date = new Date(transaction.date);
          return (
            <div key={index} className="transaction-item">
              <p>{date.toLocaleDateString()}</p>
              <p>{date.toLocaleTimeString()}</p>
              <p>{transaction.type}</p>
              <p>{formatCurrency(transaction.amount)}</p>
              <p>{transaction.description}</p>
              <p>{transaction.addedBy}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminTransaction;
