import { useEffect, useCallback } from "react";
import { ref, set, onValue, off } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { database } from "../../firebase";
import { saveCartToIndexedDB, getCartFromIndexedDB } from "../../utils/indexedDB"; // IndexedDB helpers

const useCartTracking = () => {
  const auth = getAuth();

  // Function to track and sync cart updates to Firebase & IndexedDB
  const trackCartUpdates = useCallback(async (cart, userId) => {
    if (!userId) {
      console.warn("[useCartTracking] No user ID provided. Skipping DB sync.");
      return;
    }

    try {
      const cartRef = ref(database, `users/${userId}/cart`);
      console.log("[useCartTracking] Syncing cart to Firebase & IndexedDB:", cart);

      // Save cart to Firebase
      await set(cartRef, cart);
      console.log("[useCartTracking] Cart successfully synced with Firebase!");

      // Save to IndexedDB for offline access
      await saveCartToIndexedDB(cart);
      console.log("[useCartTracking] Cart saved to IndexedDB for offline use.");
    } catch (error) {
      console.error("[useCartTracking] Error syncing cart:", error);
    }
  }, []);

  // Sync cart from Firebase to IndexedDB & localStorage on user login
  useEffect(() => {
    const authListener = onAuthStateChanged(auth, async (user) => {
      if (user && user.uid) {
        const cartRef = ref(database, `users/${user.uid}/cart`);
        console.log("[useCartTracking] User authenticated. Listening for cart updates...");

        // Listen for changes in the cart
        const unsubscribeCart = onValue(cartRef, async (snapshot) => {
          if (snapshot.exists()) {
            const cart = snapshot.val();
            console.log("[useCartTracking] Cart fetched from DB:", cart);

            // Save to localStorage
            localStorage.setItem("cart", JSON.stringify(cart));

            // Save to IndexedDB
            await saveCartToIndexedDB(cart);
            console.log("[useCartTracking] Cart cached in IndexedDB.");
          } else {
            console.warn("[useCartTracking] No cart found in DB. Clearing local & IndexedDB cache.");
            localStorage.removeItem("cart");
            await saveCartToIndexedDB({});
          }
        });

        // Cleanup listener on component unmount or user logout
        return () => {
          console.log("[useCartTracking] Cleaning up cart listener...");
          off(cartRef);
          unsubscribeCart();
        };
      } else {
        console.warn("[useCartTracking] User logged out or not authenticated. Checking IndexedDB...");

        // Try loading cart from IndexedDB
        getCartFromIndexedDB().then((cachedCart) => {
          if (cachedCart && Object.keys(cachedCart).length > 0) {
            console.log("[useCartTracking] Loaded cart from IndexedDB:", cachedCart);
            localStorage.setItem("cart", JSON.stringify(cachedCart));
          } else {
            console.warn("[useCartTracking] No cached cart found. Clearing local storage.");
            localStorage.removeItem("cart");
          }
        });
      }
    });

    // Cleanup auth listener
    return () => authListener();
  }, [auth]);

  return { trackCartUpdates };
};

export default useCartTracking;
