import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../context/AuthContext"; // Import AuthContext
import { Loader, ShieldCheck, AlertCircle, Key } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import { ref, get } from "firebase/database";
import { database } from "../firebase";
import "./AdminStatus.css";

const API_ENDPOINTS = {
  VERIFY_2FA: "https://firstfltb.com/api3/2fa/verifyTwoFactor.php",
};

const AdminStatus = () => {
  const { setIsTwoFactorVerified } = useAuth(); // Access setter from context
  const location = useLocation();
  const navigate = useNavigate();

  const { userId, twoFactorType: passedType } = location.state || {};
  const [twoFactorType, setTwoFactorType] = useState(passedType || "email");
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
  
    const fetchTwoFactorType = async () => {
  
      if (!userId) {
        console.error("No User ID found. Redirecting to login.");
        navigate("/admin/login");
        return;
      }
  
      if (passedType) {
        return;
      }
  
      try {
        const twoFactorRef = ref(database, `twoFactorAuth/${userId}`);
        const snapshot = await get(twoFactorRef);
  
        if (snapshot.exists()) {
          const data = snapshot.val();
  
          if (data.active) {
            setTwoFactorType(data.type || "email");
          } else {
            toast.error("Two-factor authentication is not active.");
          }
        } else {
          toast.error("No 2FA configuration found for this account.");
        }
      } catch (error) {
        console.error("Error fetching 2FA data:", error.message);
      }
    };
  
    fetchTwoFactorType();
  }, [userId, passedType, navigate]);
  
  const handleVerify = async () => {
  
    if (!verificationCode) {
      setErrorMessage("Please enter your verification code.");
      return;
    }
  
    setLoading(true);
    setErrorMessage("");
  
    try {
      const response = await axios.post(API_ENDPOINTS.VERIFY_2FA, {
        userId,
        type: twoFactorType,
        token: verificationCode,
      });
  
  
      if (response.data.success) {
        toast.success("Verification successful!");
        setIsTwoFactorVerified(true);
        localStorage.setItem("isTwoFactorVerified", "true"); // Persist 2FA verification
        navigate("/admin/dashboard");
      } else {
        console.error("2FA Verification Failed:", response.data.error);
        setErrorMessage(response.data.error || "Invalid verification code.");
      }
    } catch (error) {
      console.error("Error during 2FA verification:", error.message);
      toast.error("Failed to verify 2FA. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <div className="admin-status-container">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="verification-box">
        <h1>
          <ShieldCheck /> Admin Verification
        </h1>
        <p className="info-message">
          Your account requires two-factor authentication (
          {twoFactorType === "google" ? "Google Authenticator" : "Email OTP"}).
        </p>
        <div className="verification-input">
          <Key />
          <input
            type="text"
            placeholder="Enter verification code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            disabled={loading}
          />
        </div>
        {errorMessage && (
          <p className="error-message">
            <AlertCircle /> {errorMessage}
          </p>
        )}
        <button onClick={handleVerify} disabled={loading} className="verify-btn">
          {loading ? (
            <>
              <Loader className="loading-icon" /> Verifying...
            </>
          ) : (
            "Verify Code"
          )}
        </button>
      </div>
    </div>
  );
};

export default AdminStatus;
