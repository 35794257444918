import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { database, ref, set, onValue } from '../../firebase';
import { toast } from 'react-hot-toast';
import { PlusCircle, PackagePlus } from 'lucide-react';
import './AddProduct.css';

const AddProduct = ({ categories }) => {
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productCategory, setProductCategory] = useState('');
  const [productImage, setProductImage] = useState(null);
  const [comboName, setComboName] = useState('');
  const [comboPrice, setComboPrice] = useState('');
  const [comboProducts, setComboProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]); // List of all products
  const [uploading, setUploading] = useState(false);

  // Fetch all existing products for combo selection
  useEffect(() => {
    const productsRef = ref(database, 'products');
    onValue(productsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setAllProducts(Object.values(data));
      }
    });
  }, []);

  // Add single product
  const handleAddProduct = async () => {
    if (!productName || !productPrice || !productCategory || !productImage) {
      toast.error('Please fill all fields');
      return;
    }

    setUploading(true);

    try {
      const sku = `${productCategory}-${uuidv4().slice(0, 8)}`;
      const imageUrl = await uploadImageToPinataBackend(productImage);

      const newProduct = {
        id: uuidv4(),
        name: productName,
        price: parseFloat(productPrice),
        category: productCategory,
        sku,
        image: imageUrl,
        isCombo: false,
        comboProducts: null,
        available: true, // Default availability
      };

      const productRef = ref(database, `products/${newProduct.id}`);
      await set(productRef, newProduct);

      toast.success('Product added successfully!');
      setProductName('');
      setProductPrice('');
      setProductCategory('');
      setProductImage(null);
    } catch (error) {
      console.error('Error adding product:', error);
      toast.error('Failed to add product. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  // Create combo
  const handleCreateCombo = async () => {
    if (!comboName || !comboPrice || comboProducts.length === 0) {
      toast.error('Please fill all fields for the combo');
      return;
    }

    setUploading(true);

    try {
      const newCombo = {
        id: uuidv4(),
        name: comboName,
        price: parseFloat(comboPrice),
        isCombo: true,
        comboProducts, // List of selected product IDs
        available: true,
      };

      const comboRef = ref(database, `products/${newCombo.id}`);
      await set(comboRef, newCombo);

      toast.success('Combo created successfully!');
      setComboName('');
      setComboPrice('');
      setComboProducts([]);
    } catch (error) {
      console.error('Error creating combo:', error);
      toast.error('Failed to create combo. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  const uploadImageToPinataBackend = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('https://firstfltb.com/api3/AddProduct.php', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (data.status !== 'success' || !data.pinataUrl) {
        throw new Error('Pinata upload failed');
      }

      return data.pinataUrl;
    } catch (error) {
      console.error('Error uploading to Pinata:', error);
      throw error;
    }
  };

  // Toggle product selection for combo
  const toggleComboProduct = (productId) => {
    if (comboProducts.includes(productId)) {
      setComboProducts(comboProducts.filter((id) => id !== productId));
    } else {
      setComboProducts([...comboProducts, productId]);
    }
  };

  return (
    <div className="add-product-container">
      <h2>Add Product</h2>
      <div className="forms-container">
        {/* Single Product Form */}
        <div className="single-product-form">
          <h3>
            <PlusCircle size={20} /> Add Single Product
          </h3>
          <div className="form-row">
            <label>Product Name:</label>
            <input
              type="text"
              placeholder="Enter product name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>
          <div className="form-row">
            <label>Price:</label>
            <input
              type="number"
              placeholder="Enter product price"
              value={productPrice}
              onChange={(e) => setProductPrice(e.target.value)}
            />
          </div>
          <div className="form-row">
            <label>Category:</label>
            <select
              value={productCategory}
              onChange={(e) => setProductCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              {categories.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-row">
            <label>Product Image:</label>
            <input
              type="file"
              onChange={(e) => setProductImage(e.target.files[0])}
            />
          </div>
          <button onClick={handleAddProduct} disabled={uploading}>
            {uploading ? 'Uploading...' : 'Add Product'}
          </button>
        </div>

        {/* Combo Product Form */}
        <div className="combo-product-form">
          <h3>
            <PackagePlus size={20} /> Create Combo
          </h3>
          <div className="form-row">
            <label>Combo Name:</label>
            <input
              type="text"
              placeholder="Enter combo name"
              value={comboName}
              onChange={(e) => setComboName(e.target.value)}
            />
          </div>
          <div className="form-row">
            <label>Combo Price:</label>
            <input
              type="number"
              placeholder="Enter combo price"
              value={comboPrice}
              onChange={(e) => setComboPrice(e.target.value)}
            />
          </div>
          <div className="form-row">
  <label>Select Products:</label>
  <div className="combo-products-list">
    {allProducts.map((product) => (
      <div key={product.id} className="combo-product-item">
        <input
          type="checkbox"
          id={`combo-${product.id}`}
          checked={comboProducts.includes(product.id)}
          onChange={() => toggleComboProduct(product.id)}
        />
        <label htmlFor={`combo-${product.id}`}>{product.name}</label>
      </div>
    ))}
  </div>
</div>

          <button onClick={handleCreateCombo} disabled={uploading}>
            {uploading ? 'Creating Combo...' : 'Create Combo'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
