import React, { useState, useEffect } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc, updateDoc, deleteField } from "firebase/firestore";
import { onValue, ref, database } from "../firebase";
import { toast } from "react-hot-toast"; // Hot-toast for notifications
import { User, Shield, PlusCircle, Trash2 } from "lucide-react"; // Lucide icons
import "./UsersAdmin.css";

const UsersAdmin = () => {
  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [selectedTab, setSelectedTab] = useState("users"); // 'users' or 'admins'
  const [newUser, setNewUser] = useState({ name: "", email: "", password: "", role: "" });
  const [showAddUserForm, setShowAddUserForm] = useState(false); // Toggle visibility for the Add User form

  useEffect(() => {
    const usersRef = ref(database, "users");
    onValue(usersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const allUsers = Object.values(data);
        const normalUsers = allUsers.filter((user) => !user.role);
        const adminUsers = allUsers.filter((user) => user.role === "admin" || user.role === "cashier");
        setUsers(normalUsers);
        setAdmins(adminUsers);
      }
    });
  }, []);

  const handleAddUser = async () => {
    if (!newUser.name || !newUser.email || !newUser.password || !newUser.role) {
      toast.error("Please fill all required fields.");
      return;
    }

    const auth = getAuth();
    const firestore = getFirestore();

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, newUser.email, newUser.password);
      const userId = userCredential.user.uid;

      await setDoc(doc(firestore, "users", userId), {
        id: userId,
        name: newUser.name,
        email: newUser.email,
        role: newUser.role,
        registeredAt: new Date().toISOString(),
        balance: 0,
      });

      toast.success("User added successfully!");
      setNewUser({ name: "", email: "", password: "", role: "" });
      setShowAddUserForm(false); // Hide form after successful submission
    } catch (error) {
      console.error("Error adding user:", error);
      toast.error("Failed to add user.");
    }
  };

  const revokeRole = async (userId) => {
    const firestore = getFirestore();
    try {
      const userDocRef = doc(firestore, "users", userId);
      await updateDoc(userDocRef, {
        role: deleteField(),
      });

      toast.success("Role revoked successfully!");
    } catch (error) {
      console.error("Error revoking role:", error);
      toast.error("Failed to revoke role.");
    }
  };

  return (
    <div className="User-Admin-Setup-Container">
      {/* Tab Navigation */}
      <div className="User-Admin-Setup-Tabs">
        <button
          className={`User-Admin-Setup-Tab ${selectedTab === "users" ? "active" : ""}`}
          onClick={() => setSelectedTab("users")}
        >
          Users
        </button>
        <button
          className={`User-Admin-Setup-Tab ${selectedTab === "admins" ? "active" : ""}`}
          onClick={() => setSelectedTab("admins")}
        >
          Admins
        </button>
      </div>

      {/* Tab Content */}
      <div className="User-Admin-Setup-Content">
        {selectedTab === "users" && (
          <div>
            <h3>Normal Users</h3>
            {users.map((user) => (
              <div key={user.id} className="User-Admin-Setup-Item">
                <User className="User-Admin-Setup-Icon" />
                <div className="User-Admin-Setup-Details">
                  <p><strong>Name:</strong> {user.name || "N/A"}</p>
                  <p><strong>Email:</strong> {user.email}</p>
                  <p><strong>Balance:</strong> ₦{(user.balance || 0).toFixed(2)}</p>
                  <p>
                    <strong>Registered At:</strong>{" "}
                    {user.registeredAt
                      ? new Date(user.registeredAt).toLocaleString()
                      : "N/A"}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )}

        {selectedTab === "admins" && (
          <div>
            <h3>Admins & Cashiers</h3>
            {admins.map((admin) => (
              <div key={admin.id} className="User-Admin-Setup-Item">
                <Shield
                  className={`User-Admin-Setup-Icon ${admin.role === "cashier" ? "cashier" : "admin"}`}
                />
                <div className="User-Admin-Setup-Details">
                  <p><strong>Full Name:</strong> {admin.name || "N/A"}</p>
                  <p><strong>Email:</strong> {admin.email}</p>
                  <p><strong>Role:</strong> {admin.role === "cashier" ? "Cashier" : "Admin"}</p>
                </div>
                <button className="User-Admin-Setup-RevokeBtn" onClick={() => revokeRole(admin.id)}>
                  <Trash2 size={14} /> Revoke Role
                </button>
              </div>
            ))}

            {/* Add User Button */}
            <button
              className="User-Admin-Setup-AddUserBtn"
              onClick={() => setShowAddUserForm(!showAddUserForm)}
            >
              <PlusCircle size={16} /> {showAddUserForm ? "Cancel" : "Add User"}
            </button>

            {/* Add User Form */}
            {showAddUserForm && (
              <div className="User-Admin-Setup-Form">
                <h3>Add User</h3>
                <input
                  type="text"
                  placeholder="Full Name"
                  value={newUser.name}
                  onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={newUser.email}
                  onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={newUser.password}
                  onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                />
                <select
                  value={newUser.role}
                  onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                >
                  <option value="">Select Role</option>
                  <option value="admin">Admin</option>
                  <option value="cashier">Cashier</option>
                </select>
                <button onClick={handleAddUser} className="User-Admin-Setup-FormSubmitBtn">
                  <PlusCircle size={16} /> Add User
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersAdmin;
