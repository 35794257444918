export async function registerServiceWorkerAndSaveSubscription() {
  if ("serviceWorker" in navigator && "Notification" in window) {
    console.log("[App] Service Worker & Notifications supported.");

    // Check notification permission
    if (Notification.permission === "default") {
      const permission = await Notification.requestPermission();
      if (permission !== "granted") {
        console.warn("[App] Notifications permission denied.");
        return;
      }
    } else if (Notification.permission === "denied") {
      console.warn("[App] Notifications are blocked.");
      return;
    }

    try {
      // Register the Service Worker if not already registered
      const registration = await navigator.serviceWorker.register("/service-worker.js");
      console.log("[App] Service Worker registered successfully.");

      // Check if the user is already subscribed
      const existingSubscription = await registration.pushManager.getSubscription();

      if (existingSubscription) {
        console.log("[App] User already subscribed. No need to re-subscribe.");
        return; // ✅ Exit early, avoiding duplicate subscriptions
      }

      // Ensure PushManager is available
      if (!("PushManager" in window)) {
        console.error("[App] Push notifications not supported.");
        return;
      }

      // Subscribe the user (Only if not already subscribed)
      const vapidPublicKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
      if (!vapidPublicKey) {
        console.error("[App] VAPID public key missing.");
        return;
      }

      const newSubscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
      });

      console.log("[App] New Push Subscription Created:", newSubscription);

      // Save the new subscription to the backend
      const response = await fetch("https://firstfltb.com/api3/saveSubscription.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newSubscription),
      });

      const result = await response.json();
      if (result.status === "success") {
        console.log("[App] Subscription saved successfully.");
      } else {
        console.error("[App] Failed to save subscription:", result.message);
      }
    } catch (error) {
      console.error("[App] Service Worker registration or subscription failed:", error);
    }
  } else {
    console.warn("[App] Service Worker or Notifications not supported.");
  }
}

// Helper: Convert VAPID Key
function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
