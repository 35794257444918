import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Utensils, ShoppingCart, User, Star, Cog } from 'lucide-react'; // Lucide icons
import toast from 'react-hot-toast';
import './MobileNavs.css';
import { useAppContext } from '../context/AppWrapper';
import { useAuth } from '../context/AuthContext';

const MobileNavs = () => {
  const { cart, showLoader, hideLoader } = useAppContext();
  const { currentUser } = useAuth(); // Get authenticated user info
  const navigate = useNavigate();
  const location = useLocation();

  const totalItemsInCart = Object.values(cart).reduce((sum, quantity) => sum + quantity, 0);
  
  const [isVibrating, setIsVibrating] = useState(false);

  // Notify user about items in the cart (for mobile users) + Vibration effect
  useEffect(() => {
    let vibrationTimer;
    let notificationTimer;

    if (totalItemsInCart > 0) {
      // Display a toast notification every 5 minutes
      notificationTimer = setInterval(() => {
        toast('You have items in your cart!', {
          icon: <ShoppingCart size={25} color="#4CAF50" />, // Lucide icon
        });
      }, 5 * 60 * 1000);

      // Trigger vibration effect for a short duration  
      vibrationTimer = setInterval(() => {
        setIsVibrating(true);
        setTimeout(() => {
          setIsVibrating(false);
        }, 1000); // Vibration duration
      }, 2 * 20 * 100); // Vibrate every 5 minutes
    }

    return () => {
      clearInterval(notificationTimer);
      clearInterval(vibrationTimer);
    };
  }, [totalItemsInCart]);

  const handleNavigation = (path) => {
    if (location.pathname !== path) {
      showLoader();
      setTimeout(() => {
        navigate(path);
        hideLoader();
      }, 500); // Simulate loader delay
    }
  };

  const isActive = (path) => location.pathname === path;

  return (
    <>
      <nav className="mobile-nav">
        {[
          { path: '/', icon: Utensils, label: 'Menu' },
          { path: '/cart', icon: ShoppingCart, label: 'Cart', count: totalItemsInCart },
          { path: '/account', icon: User, label: 'Account' },
          {
            path: currentUser ? '/settings' : '/review',
            icon: currentUser ? Cog : Star,
            label: currentUser ? 'Settings' : 'Review',
          },
        ].map(({ path, icon: Icon, label, count }) => (
          <motion.div
            key={path}
            onClick={() => handleNavigation(path)}
            className={`nav-item ${isActive(path) ? 'active' : ''} ${isVibrating && path === '/cart' ? 'vibrating' : ''}`}
            whileHover={{ scale: 1.2, rotate: 5 }} // Slight rotate and scale on hover
            whileTap={{ scale: 0.9 }} // Shrink on tap
            animate={isActive(path) ? { scale: 1.2 } : { scale: 1 }} // Active item scaling
          >
            <Icon className="nav-icon" />
            {count > 0 && <span className="cart-count">{count}</span>}
            <span>{label}</span>
          </motion.div>
        ))}
      </nav>
    </>
  );
};

export default MobileNavs;
