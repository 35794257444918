import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import AppWrapper from './context/AppWrapper';
import AuthProvider from './context/AuthContext';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Router>
      <AppWrapper> 
        <AuthProvider>
          <App />
        </AuthProvider>
      </AppWrapper>
    </Router>
  </React.StrictMode>
);
