import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, firestore } from "../firebase"; // Use Firestore instance
import { useAuth } from "../context/AuthContext"; // Access setCurrentUser from context
import toast, { Toaster } from "react-hot-toast";
import { Loader } from "lucide-react";
import "./AdminLogin.css";

const API_ENDPOINTS = {
  FETCH_2FA_STATUS: "https://firstfltb.com/api3/2fa/getTwoFactorStatus.php",
};

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { setCurrentUser } = useAuth(); // Use setCurrentUser to update role
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("Please provide both email and password.");
      return;
    }

    setLoading(true);

    try {
      // Authenticate user
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Fetch user role from Firestore
      const userDocRef = doc(firestore, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        toast.error("User not found in database.");
        await auth.signOut();
        return;
      }

      const userData = userDoc.data();

      // Check for two-factor authentication status
      const twoFactorResponse = await fetch(API_ENDPOINTS.FETCH_2FA_STATUS, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId: user.uid }),
      });
      const twoFactorData = await twoFactorResponse.json();

// If 2FA is enabled
if (twoFactorData.twoFactorStatus?.active) {
  setCurrentUser({ ...user, role: userData.role });
  localStorage.setItem("isTwoFactorVerified", "false"); // Ensure 2FA starts as unverified
  toast.success("Two-factor authentication required!");
  navigate("/admin/status", {
    state: {
      userId: user.uid,
      twoFactorType: twoFactorData.twoFactorStatus.type,
    },
  });
  return;
}



      // If 2FA is not active, proceed with role check and redirection
      setCurrentUser({ ...user, role: userData.role });

      if (userData.role === "admin") {
        toast.success("Welcome back, Admin!");
        navigate("/admin/dashboard");
      } else if (userData.role === "cashier") {
        toast.success("Welcome back, Cashier!");
        navigate("/admin/transactions");
      } else {
        toast.error("Unauthorized access. Contact the admin for permissions.");
        await auth.signOut();
      }
    } catch (error) {
      console.error("Error during login:", error.message);
      toast.error("Failed to login. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-login-container">
      <Toaster position="top-right" reverseOrder={false} />
      <h2>Admin Login</h2>
      <form onSubmit={handleLogin}>
        <div className="admin-input-group">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading}
          />
        </div>
        <div className="admin-input-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={loading}
          />
        </div>
        <button type="submit" disabled={loading} className="admin-login-btn">
          {loading ? (
            <>
              <Loader className="spinner-icon" /> Logging in...
            </>
          ) : (
            "Login"
          )}
        </button>
      </form>
    </div>
  );
};

export default AdminLogin;
