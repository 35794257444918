import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Header from './Header';
import MobileNavs from './MobileNavs';
import TrackingWidget from './TrackingWidget'; // Import tracking widget
import { Toaster } from 'react-hot-toast'; // Use hot toast

const Layout = ({ children }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div>
      {/* Header/Nav based on screen size */}
      {isMobile ? <MobileNavs /> : <Header />}

      {/* Main Content */}
      <main>{children}</main>

      <TrackingWidget />
      <Toaster position="top-right" reverseOrder={false} />
      
    </div>
  );
};

export default Layout;
