import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaClipboardList,
  FaUsers,
  FaShoppingCart,
  FaStar,
  FaMoneyCheckAlt,
  FaBox,
  FaGift,
} from "react-icons/fa";
import { database, ref, onValue } from "../firebase";
import "./AdminDashboard.css";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [counts, setCounts] = useState({
    orders: 0,
    products: 0,
    categories: 0,
    users: 0,
    reviews: 0,
    transactions: 0,
    totalGenerated: 0,
    totalRedeemed: 0,
  });

  // Fetch total counts and voucher stats
  useEffect(() => {
    const fetchCounts = async () => {
      // Orders
      const ordersRef = ref(database, "orders");
      onValue(ordersRef, (snapshot) => {
        const orders = snapshot.val();
        setCounts((prev) => ({
          ...prev,
          orders: orders ? Object.keys(orders).length : 0,
        }));
      });

      // Products
      const productsRef = ref(database, "products");
      onValue(productsRef, (snapshot) => {
        const products = snapshot.val();
        setCounts((prev) => ({
          ...prev,
          products: products ? Object.keys(products).length : 0,
        }));
      });

      // Categories
      const categoriesRef = ref(database, "categories");
      onValue(categoriesRef, (snapshot) => {
        const categories = snapshot.val();
        setCounts((prev) => ({
          ...prev,
          categories: categories ? Object.keys(categories).length : 0,
        }));
      });

      // Users
      const usersRef = ref(database, "users");
      onValue(usersRef, (snapshot) => {
        const users = snapshot.val();
        setCounts((prev) => ({
          ...prev,
          users: users ? Object.keys(users).length : 0,
        }));
      });

      // Reviews
      const reviewsRef = ref(database, "reviews");
      onValue(reviewsRef, (snapshot) => {
        const reviews = snapshot.val();
        setCounts((prev) => ({
          ...prev,
          reviews: reviews ? Object.keys(reviews).length : 0,
        }));
      });

      // Transactions
      const transactionsRef = ref(database, "transactions");
      onValue(transactionsRef, (snapshot) => {
        const transactions = snapshot.val();
        setCounts((prev) => ({
          ...prev,
          transactions: transactions ? Object.keys(transactions).length : 0,
        }));
      });

      // Vouchers
      const vouchersRef = ref(database, "vouchers");
      onValue(vouchersRef, (snapshot) => {
        const vouchers = snapshot.val();
        if (vouchers) {
          let totalGenerated = 0;
          let totalRedeemed = 0;

          Object.values(vouchers).forEach((voucher) => {
            totalGenerated += voucher.amount || 0;
            if (voucher.redeemed) {
              totalRedeemed += voucher.amount || 0;
            }
          });

          setCounts((prev) => ({
            ...prev,
            totalGenerated,
            totalRedeemed,
          }));
        }
      });
    };

    fetchCounts();
  }, []);

  // Define dashboard sections
  const sections = [
    {
      title: "Total Orders",
      icon: <FaShoppingCart />,
      count: counts.orders,
      onClick: () => navigate("/admin/orders"),
    },
    {
      title: "Total Products",
      icon: <FaBox />,
      count: counts.products,
      onClick: () => navigate("/admin/products"),
    },
    {
      title: "Total Categories",
      icon: <FaClipboardList />,
      count: counts.categories,
      onClick: () => navigate("/admin/categories"),
    },
    {
      title: "Total Users",
      icon: <FaUsers />,
      count: counts.users,
      onClick: () => navigate("/admin/users"),
    },
    {
      title: "Total Reviews",
      icon: <FaStar />,
      count: counts.reviews,
      onClick: () => navigate("/admin/reviews"),
    },
    {
      title: "Total Transactions",
      icon: <FaMoneyCheckAlt />,
      count: counts.transactions,
      onClick: () => navigate("/admin/transactions"),
    },
  ];

  return (
    <div className="admin-dashboard-container">
      {/* Dashboard Header */}
      <header className="admin-dashboard-header">
        <h1>Admin Dashboard</h1>
      </header>

      {/* Voucher Stats as a Single Card */}
      <div className="admin-dashboard-content">
        <div className="admin-dashboard-card">
          <div className="admin-dashboard-icon">
            <FaGift />
          </div>
          <h3>Voucher Stats</h3>
          <div className="voucher-stats">
            <p>
              <strong>Total Generated:</strong> ₦{counts.totalGenerated.toLocaleString()}
            </p>
            <p>
              <strong>Total Redeemed:</strong> ₦{counts.totalRedeemed.toLocaleString()}
            </p>
          </div>
        </div>

        {/* Other Dashboard Sections */}
        {sections.map((section, index) => (
          <div
            key={index}
            className="admin-dashboard-card"
            onClick={section.onClick}
          >
            <div className="admin-dashboard-icon">{section.icon}</div>
            <h3>{section.title}</h3>
            <p className="admin-dashboard-count">{section.count}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;
