import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CheckCircle, XCircle, Copy, Loader2, Download } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import { ref, push, set } from "firebase/database";
import { database } from "../../firebase";
import { useAppContext } from "../../context/AppWrapper";
import "./Confirmation.css";

const Confirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setCart } = useAppContext();
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState("");
  const [txRef, setTxRef] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [orderDate, setOrderDate] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const estimatedDelivery = "20-35 minutes";
  const isTestMode = process.env.REACT_APP_FLW_ENV === "test";
  const isOrderSaved = useRef(false);

  // Generate and save order
  const generateAndSaveOrder = useCallback(async (formData, cart, transactionRef) => {
    if (isOrderSaved.current) return;
    isOrderSaved.current = true;
    setIsSaving(true);

    try {
      const parsedCart = Array.isArray(cart) ? cart : [];
      if (parsedCart.length === 0) {
        toast.error("Cart is empty or invalid. Cannot place an order.");
        return;
      }

      const generatedTrackingNumber = Math.floor(100000 + Math.random() * 900000).toString();
      setTrackingNumber(generatedTrackingNumber);
      setUserEmail(formData.email);
      setUserName(formData.name);
      setDeliveryMethod(formData.deliveryMethod);

      const createdAt = new Date().toISOString();
      const ordersRef = ref(database, "orders");

      const newOrderRef = push(ordersRef);
      await set(newOrderRef, {
        trackingNumber: generatedTrackingNumber,
        transactionRef,
        customerName: formData.name,
        address: formData.address,
        city: formData.city,
        state: formData.state,
        phone: formData.phone,
        email: formData.email,
        deliveryMethod: formData.deliveryMethod,
        createdAt,
        items: parsedCart.map((item) => ({
          id: item.id,
          name: item.name,
          price: item.price || 0,
          quantity: item.quantity || 1,
        })),
        status: "order_received",
      });

      setOrderDate(new Date(createdAt).toLocaleString());

      // ✅ Send order email receipt via PHP
      fetch("https://firstfltb.com/api3/sendOrderReceipt.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: formData.email,
          name: formData.name,
          transactionRef,
          trackingNumber: generatedTrackingNumber,
          orderDate: new Date(createdAt).toLocaleString(),
          estimatedDelivery,
          deliveryMethod: formData.deliveryMethod,
          items: parsedCart,
        }),
      });

      toast.success("Order saved successfully!");

      localStorage.removeItem("cart");
      localStorage.removeItem("formData");
      setCart({});
    } catch (error) {
      console.error("Error saving order:", error);
      toast.error("Failed to save order. Please contact support.");
    } finally {
      setIsSaving(false);
    }
  }, [setCart]);

  // Process transaction status
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    let txStatus = queryParams.get("status");
    const txMessage = queryParams.get("message");
    const transactionRef = queryParams.get("tx_ref");

    const formData = JSON.parse(localStorage.getItem("formData"));
    const cart = JSON.parse(localStorage.getItem("cart"));

    if (!formData || !cart) {
      toast.error("Order data is missing. Please try again.");
      navigate("/checkout");
      return;
    }

    if (isTestMode) txStatus = "successful";

    setStatus(txStatus);
    setMessage(txMessage || "Transaction processed");
    setTxRef(transactionRef);

    if (txStatus === "successful" && !isOrderSaved.current) {
      generateAndSaveOrder(formData, cart, transactionRef);
    } else if (txStatus !== "successful") {
      toast.error("Payment failed. Please try again.");
    }
  }, [location, isTestMode, generateAndSaveOrder, navigate]);

  const handleCopyTrackingNumber = () => {
    navigator.clipboard.writeText(trackingNumber);
    toast.success("Tracking number copied!");
  };

  return (
    <div className="receipt-container">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="receipt-content">
        {isSaving ? (
          <div className="saving-message">
            <Loader2 className="saving-icon" size={40} />
            <h2>Processing Your Order...</h2>
            <p>Please wait while we generate your tracking number.</p>
          </div>
        ) : status === "successful" ? (
          <div className="receipt-box">
            <h2 className="receipt-title">Payment Receipt</h2>
            <div className="receipt-details">
              <CheckCircle className="success-icon" size={60} />
              <p><strong>Name:</strong> {userName}</p>
              <p><strong>Email:</strong> {userEmail}</p>
              <p><strong>Delivery Method:</strong> {deliveryMethod}</p>
              <p><strong>Transaction Reference:</strong> {txRef}</p>
              <p>
                <strong>Tracking Number:</strong>{" "}
                <span className="tracking-number" onClick={handleCopyTrackingNumber}>
                  {trackingNumber} <Copy size={18} />
                </span>
              </p>
              <p><strong>Order Date:</strong> {orderDate}</p>
              <p><strong>Estimated Delivery:</strong> {estimatedDelivery}</p>
              <button className="save-receipt" onClick={handleCopyTrackingNumber}>
                <Download size={18} /> Save Receipt
              </button>
            </div>
          </div>
        ) : (
          <div className="receipt-box error">
            <XCircle className="error-icon" size={60} />
            <h2>Payment Failed</h2>
            <p><strong>Transaction Reference:</strong> {txRef || "N/A"}</p>
            <p>{message || "An error occurred. Please try again."}</p>
            <button onClick={() => navigate("/checkout")} className="retry-button">
              Retry Payment
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Confirmation;
