import React, { useState, useEffect } from 'react';
import { ref, onValue, remove, database, update, get } from '../firebase';
import './AdminReview.css';

const AdminReview = () => {
  const [reviews, setReviews] = useState([]);
  const [products, setProducts] = useState({});

  // Fetch reviews from the database
  useEffect(() => {
    const reviewsRef = ref(database, 'reviews');
    onValue(reviewsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setReviews(Object.entries(data));
      } else {
        setReviews([]);
      }
    });
  }, []);

  // Fetch products from the database
  useEffect(() => {
    const productsRef = ref(database, 'products');
    onValue(productsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setProducts(data);
      }
    });
  }, []);

  // Delete a review
  const handleDeleteReview = async (reviewId) => {
    const reviewRef = ref(database, `reviews/${reviewId}`);
    try {
      await remove(reviewRef);
  
      // Decrement reviews count in `adminCounts`
      const reviewsCountRef = ref(database, 'adminCounts/reviews');
      const snapshot = await get(reviewsCountRef);
      const currentCount = snapshot.exists() ? snapshot.val().count || 0 : 0;
      await update(reviewsCountRef, { count: Math.max(currentCount - 1, 0) });
  
      alert('Review deleted successfully!');
    } catch (error) {
      console.error('Error deleting review:', error);
      alert('Failed to delete review.');
    }
  };
  

  return (
    <div className="admin-review-container">
      <h2>Manage Reviews</h2>
      {reviews.length > 0 ? (
        <table className="review-table">
          <thead>
            <tr>
              <th>Product</th>
              <th>Image</th>
              <th>Rating</th>
              <th>Comment</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
  {reviews.map(([id, review]) => {
    const product = products[review.productId] || {};
    return (
      <tr key={id}>
        <td data-label="Product">{product.name || 'Service'}</td>
        <td data-label="Image">
          {product.image ? (
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
            />
          ) : (
            'No image available'
          )}
        </td>
        <td data-label="Rating">{review.rating} / 5</td>
        <td data-label="Comment">{review.text || 'No comment provided'}</td>
        <td data-label="Actions">
          <button
            className="delete-btn"
            onClick={() => handleDeleteReview(id)}
          >
            Delete
          </button>
        </td>
      </tr>
    );
  })}
</tbody>

        </table>
      ) : (
        <p>No reviews available.</p>
      )}
    </div>
  );
};

export default AdminReview;
