import React, { useState, useEffect, useMemo } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Filter, Utensils, Minus, Plus, Heart } from "lucide-react";
import Modal from "../components/Modal";
import { useAppContext } from "../context/AppWrapper";
import { get, ref, database } from "../firebase";
import useCartTracking from "./Cart/useCartTracking";
import { useAuth } from "../context/AuthContext";
import {
  getImageFromIndexedDB,
  fetchAndCacheImage,
} from "../utils/indexedDB"; // IndexedDB functions
import "./Hero.css";

const Hero = () => {
  const { cart, setCart } = useAppContext();
  const { currentUser } = useAuth();
  const { trackCartUpdates } = useCartTracking();
  const userId = useMemo(() => currentUser?.uid, [currentUser]);

  const [favorites, setFavorites] = useState({});
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [categoryImages, setCategoryImages] = useState({}); // Cached category images
  const [productImages, setProductImages] = useState({}); // Cached product images
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCategory, setModalCategory] = useState(null);

  // Function to get image from cache first, then fetch if not found
  const getCachedOrFetchImage = async (imageUrl, cacheKey) => {
    let cachedImage = await getImageFromIndexedDB(cacheKey);
    if (cachedImage) return cachedImage;

    // Fetch and store in IndexedDB (only if not cached)
    return fetchAndCacheImage(imageUrl, cacheKey);
  };

  // Fetch categories and products from Firebase
  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesSnapshot = await get(ref(database, "categories"));
        const productsSnapshot = await get(ref(database, "products"));

        if (categoriesSnapshot.exists()) {
          const categories = Object.values(categoriesSnapshot.val());
          setCategories(categories);

          // Cache category images
          const categoryImagesMap = {};
          for (const category of categories) {
            categoryImagesMap[category.id] = await getCachedOrFetchImage(
              category.iconUrl,
              `category_${category.id}_image`
            );
          }
          setCategoryImages(categoryImagesMap);
        }

        if (productsSnapshot.exists()) {
          const products = Object.values(productsSnapshot.val());
          setProducts(products);

          // Cache product images
          const productImagesMap = {};
          for (const product of products) {
            productImagesMap[product.id] = await getCachedOrFetchImage(
              product.image,
              `product_${product.id}_image`
            );
          }
          setProductImages(productImagesMap);
        }
      } catch (error) {
        console.error("[Hero] Error fetching data:", error);
        toast.error("Failed to load menu.");
      }
    };

    fetchData();
  }, []);

  // Load cart and favorites from localStorage on component mount
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || {};
    const savedFavorites = JSON.parse(localStorage.getItem("favorites")) || {};
    if (Object.keys(savedCart).length > 0) setCart(savedCart);
    setFavorites(savedFavorites);
  }, [setCart]);

  // Save cart and favorites to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
    localStorage.setItem("favorites", JSON.stringify(favorites));
  }, [cart, favorites]);

  // Filter products by selected categories
  const filteredProducts = selectedCategories.length
    ? products.filter((product) => selectedCategories.includes(product.category))
    : products;

  const formatPrice = (price) =>
    new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(price);

// Add to cart
const handleAddToCart = (id, name) => {
  console.log('Current User:', currentUser); // Ensure `currentUser` is available
  setCart((prevCart) => {
    const updatedCart = { ...prevCart, [id]: (prevCart[id] || 0) + 1 };
    console.log('Cart Before Add:', prevCart);
    console.log('Cart After Add:', updatedCart);

    if (currentUser) {
      trackCartUpdates(updatedCart, currentUser);
      toast.success(`${name} added to cart!`); // Notify when successfully added
    } else {
      console.log('User not authenticated. Skipping DB sync.');
      toast.success(`${name} added to cart!`); // Notify for local update
    }
    return updatedCart;
  });
};

// Remove from cart
const handleRemoveFromCart = (id, name) => {
  setCart((prevCart) => {
    const updatedCart = { ...prevCart };
    if (updatedCart[id] > 1) {
      updatedCart[id] -= 1;
      toast.info(`Quantity of ${name} reduced!`); // Notify for quantity reduction
    } else {
      delete updatedCart[id];
      toast.error(`${name} removed from cart!`); // Notify for item removal
    }

    if (currentUser) {
      trackCartUpdates(updatedCart, currentUser); // Sync with DB for authenticated users
    } else {
      console.log('User not authenticated. Changes are local only.');
    }
    return updatedCart;
  });
};




  // Add/Remove from favorites
  const toggleFavorite = (id) => {
    setFavorites((prevFavorites) => {
      const updatedFavorites = { ...prevFavorites };
      if (updatedFavorites[id]) {
        delete updatedFavorites[id];
        toast("Removed from favorites!", { icon: "❌" });
      } else {
        updatedFavorites[id] = true;
        toast("Added to favorites!", { icon: "❤️" });
      }
      return updatedFavorites;
    });
  };

  // Handle category filter toggle
  const handleCategoryChange = (categoryId) => {
    setSelectedCategories((prev) =>
      prev.includes(categoryId)
        ? prev.filter((id) => id !== categoryId)
        : [...prev, categoryId]
    );
  };

  // Handle modal open/close for "See More"
  const handleOpenModal = (category) => {
    setModalCategory(category);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalCategory(null);
    setModalOpen(false);
  };

  return (
    <div id="hero-container" className="Home-Hero-Container">
      <Toaster position="top-right" reverseOrder={false} />
      <h2 className="Home-Hero-StickyHeader">
        <Utensils className="Home-Hero-Icon" /> Our Menu
      </h2>

      {/* Filter Button */}
      <div className="Home-Hero-FilterContainer">
        <button
          className="Home-Hero-FilterBtn"
          onClick={() => setShowFilterOptions((prev) => !prev)}
        >
          <Filter /> Filter
        </button>
        {showFilterOptions && (
          <div className="Home-Hero-FilterDropdown">
            {categories.map((category) => (
              <label key={category.id} className="Home-Hero-FilterOption">
                <input
                  type="checkbox"
                  value={category.id}
                  checked={selectedCategories.includes(category.id)}
                  onChange={() => handleCategoryChange(category.id)}
                />
                <img
                  src={categoryImages[category.id] || category.iconUrl}
                  alt={category.name}
                  className="Home-Hero-CategoryIcon"
                />
                {category.name}
              </label>
            ))}
          </div>
        )}
      </div>

      {/* Grouped Content by Category */}
      {categories.map((category) => {
        const categoryProducts = filteredProducts.filter(
          (product) => product.category === category.id
        );

        if (categoryProducts.length) {
          return (
            <div key={category.id}>
              <div className="Home-Hero-SectionHeader">
                <h3>
                  <img
                    src={categoryImages[category.id] || category.iconUrl}
                    alt={category.name}
                    className="Home-Hero-CategoryHeaderIcon"
                  />{" "}
                  {category.name}
                </h3>
                <button
                  className="Home-Hero-SeeMoreBtn"
                  onClick={() => handleOpenModal(category)}
                >
                  See More
                </button>
              </div>
              <div className="Home-Hero-MenuGrid">
                {categoryProducts.slice(0, 3).map((product) => (
                  <div key={product.id} className="Home-Hero-MenuItem">
                    <img
                      src={productImages[product.id] || product.image}
                      alt={product.name}
                      className="Home-Hero-MenuImage"
                    />
                    <div className="Home-Hero-MenuDetails">
                      <div className="Home-Hero-MenuName">
                        {product.name}{" "}
                        <Heart
                          className={`Home-Hero-FavoriteIcon ${
                            favorites[product.id] ? "favorited" : ""
                          }`}
                          onClick={() => toggleFavorite(product.id)}
                        />
                      </div>
                      <div className="Home-Hero-MenuPrice">
                        {product.discountedPrice ? (
                          <>
                            <span
                              style={{
                                textDecoration: "line-through",
                                marginRight: "8px",
                              }}
                            >
                              {formatPrice(product.price)}
                            </span>
                            <span style={{ color: "red" }}>
                              {formatPrice(product.discountedPrice)}
                            </span>
                          </>
                        ) : (
                          formatPrice(product.price)
                        )}
                      </div>
                      <div className="Home-Hero-MenuActions">
                        <button
                          onClick={() =>
                            handleRemoveFromCart(product.id, product.name)
                          }
                        >
                          <Minus size={16} />
                        </button>
                        <span>{cart[product.id] || 0}</span>
                        <button
                          onClick={() =>
                            handleAddToCart(product.id, product.name)
                          }
                        >
                          <Plus size={16} />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        }
        return null;
      })}

      {/* Modal */}
      {modalOpen && modalCategory && (
        <Modal isOpen={modalOpen} onClose={handleCloseModal}>
          <h3>{modalCategory.name} Menu</h3>
          <div className="Home-Hero-MenuGrid">
            {filteredProducts
              .filter((product) => product.category === modalCategory.id)
              .map((product) => (
                <div key={product.id} className="Home-Hero-MenuItem">
                  <img
                    src={productImages[product.id] || product.image}
                    alt={product.name}
                    className="Home-Hero-MenuImage"
                  />
                  <div className="Home-Hero-MenuDetails">
                    <div className="Home-Hero-MenuName">
                      {product.name}{" "}
                      <Heart
                        className={`Home-Hero-FavoriteIcon ${
                          favorites[product.id] ? "favorited" : ""
                        }`}
                        onClick={() => toggleFavorite(product.id)}
                      />
                    </div>
                    <div className="Home-Hero-MenuPrice">
                      {product.discountedPrice ? (
                        <>
                          <span
                            style={{
                              textDecoration: "line-through",
                              marginRight: "8px",
                            }}
                          >
                            {formatPrice(product.price)}
                          </span>
                          <span style={{ color: "red" }}>
                            {formatPrice(product.discountedPrice)}
                          </span>
                        </>
                      ) : (
                        formatPrice(product.price)
                      )}
                    </div>
                    <div className="Home-Hero-MenuActions">
                      <button
                        onClick={() =>
                          handleRemoveFromCart(product.id, product.name)
                        }
                      >
                        <Minus size={16} />
                      </button>
                      <span>{cart[product.id] || 0}</span>
                      <button
                        onClick={() =>
                          handleAddToCart(product.id, product.name)
                        }
                      >
                        <Plus size={16} />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Hero;
