import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Cart from "./pages/Cart";
import Account from "./pages/Account";
import Review from "./pages/Review";
import Checkout from "./pages/Checkout";
import Confirmation from "./pages/checkout/Confirmation";
import UserHome from "./Dashboard/UserHome";
import UserSettings from "./Dashboard/UserSettings";
import TrackOrder from "./Dashboard/TrackOrder";
import ProtectedRoute from "./context/ProtectedRoute";
import OrderHistory from "./Dashboard/OrderHistory";
import VoucherPage from "./Dashboard/VoucherPage";
import AdminRoutes from "./Admin/AdminRoutes";
import { registerServiceWorkerAndSaveSubscription } from "./serviceWorkerRegister";
import { useAuth } from "./context/AuthContext";

function App() {
  const { currentUser } = useAuth();

  // Register service worker on app load
  useEffect(() => {
    registerServiceWorkerAndSaveSubscription();
  }, []);

  return (
    <Routes>
      {/* Admin Routes */}
      <Route path="/admin/*" element={<AdminRoutes />} />

      {/* Main App with Layout */}
      <Route
        path="/*"
        element={
          <Layout>
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/account" element={<Account />} />
              <Route path="/review" element={<Review />} />
              <Route path="/confirmation" element={<Confirmation />} />

              {/* Guest Checkout */}
              <Route
                path="/checkout"
                element={
                  currentUser ? (
                    <ProtectedRoute roles={["customer"]}>
                      <Checkout />
                    </ProtectedRoute>
                  ) : (
                    <Checkout />
                  )
                }
              />

              {/* Authenticated Customer Routes */}
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute roles={["customer"]}>
                    <UserHome />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute roles={["customer"]}>
                    <UserSettings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/track-order"
                element={
                  <ProtectedRoute roles={["customer"]}>
                    <TrackOrder />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/order-history"
                element={
                  <ProtectedRoute roles={["customer"]}>
                    <OrderHistory />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/vouchers"
                element={
                  <ProtectedRoute roles={["customer"]}>
                    <VoucherPage />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Layout>
        }
      />
    </Routes>
  );
}

export default App;
