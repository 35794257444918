import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AdminLogin from "./AdminLogin";
import AdminDashboard from "./AdminDashboard";
import AdminCategories from "./AdminCategories";
import AdminProducts from "./AdminProducts";
import UsersAdmin from "./UsersAdmin";
import AdminTransaction from "./AdminTransaction";
import AdminSettings from "./AdminSettings";
import AdminReview from "./AdminReview";
import BestSeller from "./BestSeller";
import AdminOrders from "./AdminOrders";
import AdminActivities from "./AdminActivities";
import AdminStatus from "./AdminStatus"; // Import AdminStatus
import ProtectedRoute from "../context/ProtectedRoute";
import AdminLayout from "./AdminLayout";
import AdminCreateAccount from "./AdminCreateAccount";
import { database, ref, onValue } from "../firebase";

const AdminRoutes = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const categoriesRef = ref(database, "categories");
    onValue(categoriesRef, (snapshot) => {
      const data = snapshot.val();
      setCategories(data ? Object.values(data) : []);
    });
  }, []);

  return (
    <Routes>
      {/* Login Route */}
      <Route path="login" element={<AdminLogin />} />

      {/* Admin Status (no header or layout until verified) */}
      <Route path="status" element={<AdminStatus />} />

      {/* Protected Admin Routes */}
      <Route
        path="/"
        element={
          <ProtectedRoute roles={["admin", "cashier"]}>
            <AdminLayout />
          </ProtectedRoute>
        }
      >
        <Route path="dashboard" element={<AdminDashboard />} />
        <Route path="products" element={<AdminProducts categories={categories} />} />
        <Route path="categories" element={<AdminCategories />} />
        <Route path="users" element={<UsersAdmin />} />
        <Route path="transactions" element={<AdminTransaction />} />
        <Route path="settings" element={<AdminSettings />} />
        <Route path="reviews" element={<AdminReview />} />
        <Route path="best-sellers" element={<BestSeller />} />
        <Route path="orders" element={<AdminOrders />} />
        <Route path="activities" element={<AdminActivities />} />
        <Route path="create-account" element={<AdminCreateAccount />} />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;
