import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Utensils, ShoppingCart, User, Star, Settings } from "lucide-react"; // Lucide icons
import "./Header.css";
import { useAppContext } from "../context/AppWrapper";
import { useAuth } from "../context/AuthContext";
import toast, { Toaster } from "react-hot-toast";

const Header = () => {
  const { cart, showLoader, hideLoader } = useAppContext();
  const { currentUser } = useAuth(); // Get authenticated user info
  const totalItemsInCart = Object.values(cart).reduce(
    (sum, quantity) => sum + quantity,
    0
  );

  const [isVibrating, setIsVibrating] = useState(false);

  useEffect(() => {
    let vibrationTimer;
    let notificationTimer;

    if (totalItemsInCart > 0) {

      // Display a toast notification every 5 minutes
      notificationTimer = setInterval(() => {
        toast(
          <div>
            <strong><ShoppingCart size={25} color="#4CAF50" />You have items in your cart!</strong>
          </div>,
          {
            duration: 5000, // Toast lasts 5 seconds
          }
        );
      }, 20 * 60 * 1000);

      // Trigger vibration effect for a short duration
      vibrationTimer = setInterval(() => {
        setIsVibrating(true);
        setTimeout(() => {
          setIsVibrating(false);
        }, 1000); // Vibration duration
      }, 2 * 20 * 100); // Vibrate every 5 minutes
    }

    // Cleanup timers
    return () => {
      clearInterval(vibrationTimer);
      clearInterval(notificationTimer);
    };
  }, [totalItemsInCart, cart]);

  const handleNavigation = (path) => {
    showLoader();
    setTimeout(() => {
      hideLoader();
    }, 500); // Simulate loader delay
  };

  return (
    <header className="header">
      <Toaster position="top-right" reverseOrder={false} />
      <nav className="desktop-nav">
        {/* Menu Link */}
        <NavLink
          to="/"
          className={({ isActive }) =>
            `nav-link menu-link ${isActive ? "active-nav" : ""}`
          }
          onClick={() => handleNavigation("/")}
        >
          <Utensils className="nav-icon" />
          <span>Menu</span>
        </NavLink>

        {/* Cart Link */}
        <NavLink
          to="/cart"
          className={({ isActive }) =>
            `nav-link cart-link ${isVibrating ? "vibrating" : ""} ${
              isActive ? "active-nav" : ""
            }`
          }
          onClick={() => handleNavigation("/cart")}
        >
          <ShoppingCart className="nav-icon" />
          <span>Cart</span>
          {totalItemsInCart > 0 && (
            <span className="cart-count">{totalItemsInCart}</span>
          )}
        </NavLink>

        {/* Account Link */}
        <NavLink
          to="/account"
          className={({ isActive }) =>
            `nav-link account-link ${isActive ? "active-nav" : ""}`
          }
          onClick={() => handleNavigation("/account")}
        >
          <User className="nav-icon" />
          <span>Account</span>
        </NavLink>

        {/* Review/Settings Link */}
        <NavLink
          to={currentUser ? "/settings" : "/review"}
          className={({ isActive }) =>
            `nav-link review-link ${isActive ? "active-nav" : ""}`
          }
          onClick={() => handleNavigation(currentUser ? "/settings" : "/review")}
        >
          {currentUser ? <Settings className="nav-icon" /> : <Star className="nav-icon" />}
          <span>{currentUser ? "Settings" : "Review"}</span>
        </NavLink>
      </nav>
    </header>
  );
};

export default Header;
