import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { FaTrash, FaShoppingCart } from "react-icons/fa";
import { LogIn, ShoppingCart, Loader } from "lucide-react";
import { useAppContext } from "../context/AppWrapper";
import { useAuth } from "../context/AuthContext";
import useCartTracking from "./Cart/useCartTracking";
import { ref, onValue, database } from "../firebase";
import {
  fetchAndCacheImage,
  getImageFromIndexedDB,
} from "../utils/indexedDB"; // ✅ Use IndexedDB for caching
import Modal from "../components/CheckoutModal";
import "./Cart.css";

const Cart = () => {
  const { cart, setCart } = useAppContext();
  const { currentUser } = useAuth();
  const { trackCartUpdates } = useCartTracking();
  const [products, setProducts] = useState([]);
  const [productImages, setProductImages] = useState({}); // Cached product images
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Formatter for NGN prices
  const formatPrice = (price) =>
    new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(price);

  // ✅ Fetch products from Firebase and check IndexedDB first
  useEffect(() => {
    const fetchProducts = async () => {
      const productsRef = ref(database, "products");
      onValue(productsRef, async (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const productList = Object.values(data);
          setProducts(productList);

          // ✅ Check IndexedDB first before fetching from Pinata
          const imagesMap = {};
          for (const product of productList) {
            let imageUrl = await getImageFromIndexedDB(`product_${product.id}_image`);

            if (!imageUrl) {
              // ❌ Not found in IndexedDB? Fetch and store it.
              imageUrl = await fetchAndCacheImage(product.image, `product_${product.id}_image`);
            }

            imagesMap[product.id] = imageUrl;
          }

          setProductImages(imagesMap);
        }
      });
    };

    fetchProducts();
  }, []);

  // ✅ Keep cart counts intact by syncing changes properly
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
    if (currentUser) {
      trackCartUpdates(cart); // Sync cart for logged-in users
    }
  }, [cart, currentUser, trackCartUpdates]);

  // ✅ Add to cart (Preserve count)
  const handleAddToCart = (id) => {
    setCart((prevCart) => {
      const updatedCart = { ...prevCart, [id]: (prevCart[id] || 0) + 1 };
      if (currentUser) {
        trackCartUpdates(updatedCart, currentUser);
      }
      toast.success("Item added to cart!");
      return updatedCart;
    });
  };

  // ✅ Remove from cart (Preserve count)
  const handleRemoveFromCart = (id) => {
    setCart((prevCart) => {
      const updatedCart = { ...prevCart };
      if (updatedCart[id] > 1) {
        updatedCart[id] -= 1;
        toast.info(`Quantity reduced!`);
      } else {
        delete updatedCart[id];
        toast.error(`Item removed from cart!`);
      }
      if (currentUser) {
        trackCartUpdates(updatedCart, currentUser);
      }
      return updatedCart;
    });
  };

  // ✅ Empty Cart
  const handleEmptyCart = () => {
    setCart({});
    toast.error("Cart emptied!");
  };

  // ✅ Checkout Modal Handlers
  const openCheckoutModal = () => {
    if (Object.keys(cart).length === 0) {
      toast.error("Your cart is empty!");
      return;
    }
    setCheckoutModalOpen(true);
  };

  const closeCheckoutModal = () => {
    setCheckoutModalOpen(false);
  };

  const proceedToGuestCheckout = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setCheckoutModalOpen(false);
      navigate("/checkout", { state: { cartItems, total } });
      toast.success("Proceeding as a guest!");
      setIsLoading(false);
    }, 1500);
  };

  const proceedToAccount = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setCheckoutModalOpen(false);
      navigate("/account");
      toast.success("Redirecting to account page!");
      setIsLoading(false);
    }, 1500);
  };

  // ✅ Convert the cart object to an array of items (KEEPING COUNTS)
  const cartItems = Object.keys(cart)
    .map((id) => {
      const product = products.find((item) => item.id === id);
      return product ? { ...product, quantity: cart[id] } : null;
    })
    .filter(Boolean);

  // ✅ Calculate Total Price
  const total = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);

  // ✅ Generate Product Suggestions
  const getSuggestions = () => {
    const cartIds = Object.keys(cart);
    return products
      .filter((product) => !cartIds.includes(product.id))
      .sort(() => Math.random() - 0.5)
      .slice(0, 4);
  };

  const suggestions = getSuggestions();

  return (
    <div className="cart-container">
      <Toaster position="top-right" reverseOrder={false} />
      <h1>Your Cart</h1>

      {cartItems.length > 0 ? (
        <>
          <ul className="cart-items">
            {cartItems.map((item) => (
              <li key={item.id} className="cart-item">
                <img
                  src={productImages[item.id] || item.image}
                  alt={item.name}
                  className="cart-item-image"
                />
                <div className="cart-item-details">
                  <h4>{item.name}</h4>
                  <p>{formatPrice(item.price)}</p>
                </div>
                <div className="cart-item-actions">
                  <button className="cart-btn red" onClick={() => handleRemoveFromCart(item.id)}>
                    -
                  </button>
                  <span>{cart[item.id] || 0}</span> {/* ✅ Cart count remains */}
                  <button className="cart-btn green" onClick={() => handleAddToCart(item.id)}>
                    +
                  </button>
                </div>
              </li>
            ))}
          </ul>
          <div className="cart-footer">
            <h3>Total: {formatPrice(total)}</h3>
            <div className="cart-actions">
              <button onClick={handleEmptyCart} className="empty-cart-btn">
                <FaTrash /> Empty Cart
              </button>
              <button onClick={openCheckoutModal} className="checkout-btn">
                <FaShoppingCart /> Checkout
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="empty-cart">
          <p>Your cart is empty! Add items from the menu to get started.</p>
        </div>
      )}

      {/* ✅ Suggestions Section */}
      {suggestions.length > 0 && (
        <div className="suggestions-container">
          <h3>Suggestions for You</h3>
          <div className="suggestion-grid">
            {suggestions.map((item) => (
              <div key={item.id} className="suggestion-item">
                <img src={productImages[item.id] || item.image} alt={item.name} className="suggestion-image" />
                <div className="suggestion-details">
                  <h4>{item.name}</h4>
                  <p>{formatPrice(item.price)}</p>
                  <button className="add-suggestion-btn" onClick={() => handleAddToCart(item.id)}>
                    Add to Cart
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* ✅ Checkout Modal */}
      {checkoutModalOpen && (
        <Modal isOpen={checkoutModalOpen} onClose={closeCheckoutModal}>
          <h3>Choose Checkout Option</h3>
          <button className="modal-btn guest-btn" onClick={proceedToGuestCheckout} disabled={isLoading}>
            {isLoading ? <Loader className="spinner" /> : <ShoppingCart />} Checkout as Guest
          </button>
          <button className="modal-btn account-btn" onClick={proceedToAccount} disabled={isLoading}>
            {isLoading ? <Loader className="spinner" /> : <LogIn />} Login / Create Account
          </button>
        </Modal>
      )}
    </div>
  );
};

export default Cart;
