import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  auth,
  onAuthStateChanged,
  signOut,
  updateProfile,
  updatePassword,
  firestore,
} from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useAppContext } from './AppWrapper';
import useCartTracking from '../pages/Cart/useCartTracking'; // Import useCartTracking

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null); // Store the authenticated user
  const [loading, setLoading] = useState(true); // Track auth state resolution
  const [isTwoFactorVerified, setIsTwoFactorVerified] = useState(undefined); // Track 2FA verification status
  const { showLoader, hideLoader } = useAppContext();
  const { handleLogout } = useCartTracking(); // Destructure handleLogout from useCartTracking

  // Fetch user role from Firestore
  const fetchUserRole = async (uid) => {
    try {
      const userDocRef = doc(firestore, 'users', uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        return userData.role || 'customer'; // Default to 'customer' if no role is set
      }
      return 'customer'; // Default role if document is missing
    } catch (error) {
      console.error('Error fetching user role:', error.message);
      throw error;
    }
  };

  // Fetch Two-Factor Authentication Status
  const fetchTwoFactorStatus = async (uid) => {
    try {
      const response = await fetch('https://firstfltb.com/api3/2fa/getTwoFactorStatus.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: uid }),
      });
      const data = await response.json();

      if (data.twoFactorStatus?.active) {
        return false; // Not verified yet if 2FA is active
      }
      return true; // Verified if 2FA is not active
    } catch (error) {
      console.error('Error fetching 2FA status:', error.message);
      throw error;
    }
  };

  // Monitor authentication state and fetch roles and 2FA status
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true); // Ensure loading state is active during this process
      if (user) {
        console.log('User Authenticated:');
        try {
          const role = await fetchUserRole(user.uid);
          const twoFactorVerified = await fetchTwoFactorStatus(user.uid);

          setCurrentUser({ ...user, role });
          setIsTwoFactorVerified(twoFactorVerified);
        } catch (error) {
          console.error('Error during authentication state resolution:', error.message);
          setCurrentUser(null);
          setIsTwoFactorVerified(undefined);
        }
      } else {
        setCurrentUser(null);
        setIsTwoFactorVerified(undefined);
        console.log('User Not Authenticated');
      }
      setLoading(false); // Stop loading after resolution
      hideLoader();
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, [hideLoader]);

  const logout = async () => {
    try {
      showLoader();
      handleLogout(currentUser); // Clear cart from local storage but retain in DB
      console.log('Logging out user:', currentUser);
      await signOut(auth);
      setCurrentUser(null);
      setIsTwoFactorVerified(undefined);
    } catch (error) {
      console.error('Error during logout:', error.message);
    } finally {
      hideLoader();
    }
  };

  const updateUserProfile = async (profileData) => {
    if (!currentUser) {
      throw new Error('No authenticated user to update.');
    }

    try {
      showLoader();
      await updateProfile(auth.currentUser, {
        displayName: profileData.fullName,
        phoneNumber: profileData.mobileNumber,
      });

      setCurrentUser({ ...currentUser, displayName: profileData.fullName });
    } catch (error) {
      console.error('Error updating profile:', error.message);
      throw error;
    } finally {
      hideLoader();
    }
  };

  const updateUserPassword = async (newPassword) => {
    if (!currentUser) {
      throw new Error('No authenticated user to update.');
    }

    try {
      showLoader();
      await updatePassword(auth.currentUser, newPassword);
    } catch (error) {
      console.error('Error updating password:', error.message);
      throw error;
    } finally {
      hideLoader();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        isTwoFactorVerified,
        setIsTwoFactorVerified,
        logout,
        updateUserProfile,
        updateUserPassword,
        loading,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
