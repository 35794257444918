import React from "react";
import {
  Box,
  Truck,
  User,
  MapPin,
  Mail,
  Phone,
  CheckCircle,
} from "lucide-react";
import  { Toaster } from 'react-hot-toast';
import "./OrderSummary.css";

// Helper function to format currency
const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(amount);
};

const OrderSummary = ({
  cartItems,
  total,
  formData,
  handleInputChange,
  isMobile,
  step,
}) => {
  const isSticky = !isMobile && step === 2;
  const isMobileDisplay = isMobile && step === 2;

  
  

  return (
    <div
      className={`order-summary-section ${isSticky ? "sticky" : ""} ${
        isMobileDisplay ? "mobile-display" : ""
      }`}
    >
      <h2 className="order-summary-title">Order Summary</h2>
      <Toaster position="top-right" reverseOrder={false} />


      {/* Cart Items Section */}
      <div className="summary-section">
        <h3>
          <Box className="summary-icon" /> Items
        </h3>
        <div className="cart-grid scrollable-container">
          {cartItems.map((item) => (
            <div key={item.id} className="cart-item">
              <img
                src={item.image}
                alt={item.name}
                className="cart-item-image"
              />
              <div>
                <h4>{item.name}</h4>
                <p>Qty: {item.quantity}</p>
                <p>{formatCurrency(item.price * item.quantity)}</p>
              </div>
            </div>
          ))}
        </div>
        <p className="total-cost">Total: {formatCurrency(total)}</p>
      </div>

      {/* Delivery and Contact Details */}
      <div className="summary-section">
        <h3>
          <Truck className="summary-icon" /> Delivery & Contact
        </h3>
        <div className="contact-item">
          <User className="contact-icon" />
          <div className="contact-text">
            <strong>Name:</strong> {formData.name || "Not Provided"}
          </div>
        </div>
        <div className="contact-item">
          <Phone className="contact-icon" />
          <div className="contact-text">
            <strong>Phone:</strong> {formData.phone || "Not Provided"}
          </div>
        </div>
        <div className="contact-item">
          <Mail className="contact-icon" />
          <div className="contact-text">
            <strong>Email:</strong> {formData.email || "Not Provided"}
          </div>
        </div>
        <div className="contact-item">
          <MapPin className="contact-icon" />
          <div className="contact-text">
            <strong>Address:</strong> {formData.address || "Not Provided"},{" "}
            {formData.city}, {formData.state}
          </div>
        </div>
        <div className="contact-item">
          <Truck className="contact-icon" />
          <div className="contact-text">
            <strong>Delivery Method:</strong>{" "}
            {formData.deliveryMethod || "Not Provided"}
          </div>
        </div>
      </div>

      {/* Terms and Conditions */}
      <div className="terms-section">
        <h3>
          <CheckCircle className="summary-icon" /> Terms and Conditions
        </h3>
        <div className="terms-container">

          <label htmlFor="agreeToTerms" className="terms-label">
            By placing this order, I agree to the{" "}
            <span className="terms-highlight">terms and conditions</span> of the
            service.
          </label>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
