import React, { useState, useEffect } from "react";
import {
  Search,
  X,
  Loader2,
  Clock,
  Box,
  Truck,
  CheckCircle,
  RefreshCw,
} from "lucide-react"; // Lucide icons
import toast, { Toaster } from "react-hot-toast";
import "./TrackingWidget.css";
import { ref, get } from "firebase/database"; // Firebase imports
import { database } from "../firebase"; // Firebase config

const TrackingWidget = () => {
  const [isOpen, setIsOpen] = useState(false); // Toggle widget
  const [trackingNumber, setTrackingNumber] = useState(""); // Track user input
  const [orderDetails, setOrderDetails] = useState(null); // Store order details
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [showHint, setShowHint] = useState(false); // Toggle hint periodically
  const [position, setPosition] = useState({ x: 20, y: 20 }); // Initial position
  const [isDragging, setIsDragging] = useState(false); // Drag state

  const sections = [
    { label: "Order Received", icon: <Box />, statusKey: "order_received" },
    { label: "Preparing Order", icon: <Clock />, statusKey: "preparing" },
    { label: "Packing Order", icon: <Box />, statusKey: "packing" },
    { label: "Out for Delivery", icon: <Truck />, statusKey: "out_for_delivery" },
    { label: "Delivered", icon: <CheckCircle />, statusKey: "delivered" },
  ];

  useEffect(() => {
    const hintTimer = setInterval(() => {
      setShowHint(true);
      setTimeout(() => setShowHint(false), 3000);
    }, 20000);

    return () => clearInterval(hintTimer);
  }, []);

  const toggleWidget = () => {
    setIsOpen((prev) => !prev);
    if (!isOpen) {
      setOrderDetails(null); // Reset order details when reopening the widget
      setTrackingNumber(""); // Clear tracking number input
    }
  };

  const handleCheckOrder = async () => {
    if (!trackingNumber) {
      toast.error("Please enter your tracking number!");
      return;
    }

    setIsLoading(true);
    try {
      const ordersRef = ref(database, "orders");
      const snapshot = await get(ordersRef);

      if (snapshot.exists()) {
        const orders = snapshot.val();
        const order = Object.values(orders).find(
          (o) => o.trackingNumber === trackingNumber
        );

        if (order) {
          setOrderDetails({
            name: order.customerName || "Unknown Customer",
            date: order.createdAt
              ? new Date(order.createdAt).toLocaleDateString()
              : "N/A",
            status: order.status,
          });
          toast.success("Order found!");
        } else {
          toast.error("No order found with this tracking number.");
        }
      } else {
        toast.error("No orders available.");
      }
    } catch (error) {
      toast.error("Error fetching order. Please try again.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDragStart = (e) => {
    setIsDragging(true);
    e.dataTransfer.setDragImage(new Image(), 0, 0); // Prevent default drag image
  };

  const handleDrag = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - 30, // Center the icon while dragging
        y: e.clientY - 30,
      });
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const resetForm = () => {
    setTrackingNumber("");
    setOrderDetails(null);
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />

      {/* Floating Icon with Hint */}
      <div
        className="tracking-widget-icon"
        onClick={toggleWidget}
        style={{ left: `${position.x}px`, top: `${position.y}px` }}
        draggable="true"
        onDragStart={handleDragStart}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
      >
        {isOpen ? <X size={24} /> : <Search size={24} />}
        {showHint && !isOpen && <div className="tracking-hint">Track Your Order</div>}
      </div>

      {/* Modal for Tracking */}
      {isOpen && (
        <div className="tracking-widget-modal">
          <div className="modal-header">
            <h3>Track Your Order</h3>
            <button className="widget-close-btn" onClick={toggleWidget}>
              <X size={20} />
            </button>
          </div>

          <div className="modal-body">
            {!orderDetails ? (
              <>
                <p>Enter your tracking number to check your order status.</p>
                <input
                  type="text"
                  placeholder="Enter Tracking Number"
                  value={trackingNumber}
                  onChange={(e) => setTrackingNumber(e.target.value)}
                  className="tracking-input"
                />
                <button className="check-btn" onClick={handleCheckOrder} disabled={isLoading}>
                  {isLoading ? <Loader2 className="loader-icon" size={20} /> : "Track"}
                </button>
              </>
            ) : (
              <>
                <div className="order-details">
                  <h4>Order Details:</h4>
                  <p>
                    <strong>Customer Name:</strong> {orderDetails.name}
                  </p>
                  <p>
                    <strong>Order Date:</strong> {orderDetails.date}
                  </p>
                </div>

                <div className="order-status-container">
                  <h4>Order Status:</h4>
                  <div className="order-status-steps">
                    {sections.map((section) => (
                      <div
                        key={section.statusKey}
                        className={`order-step ${
                          orderDetails.status === section.statusKey ? "active-step" : ""
                        }`}
                      >
                        {section.icon}
                        <span>{section.label}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <button className="track-again-btn" onClick={resetForm}>
                  <RefreshCw size={20} /> Track Another Order
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TrackingWidget;
