import React, { useState } from 'react';
import { CreditCard, Bell, Lock, Megaphone, MessageCircle, Database } from 'lucide-react'; // Matching icons
import { toast } from 'react-hot-toast'; // Hot Toast notifications
import './AdminSettings.css';
import PaymentGateway from './AdminSettings/PaymentGateway';
import AdminNotifications from './AdminSettings/AdminNotifications';
import TwoFactorAuth from './AdminSettings/TwoFactorAuth';
import Announcements from './AdminSettings/AdminAnnouncements';
import LiveChat from './AdminSettings/LiveChat';
import DatabaseBackup from './AdminSettings/DatabaseBackup';

const AdminSettings = () => {
  const [selectedSettingModal, setSelectedSettingModal] = useState(null);

  const settingsOptions = [
    {
      id: 'payment-gateway',
      title: 'Payment Gateway',
      icon: <CreditCard size={24} />,
      description: 'Manage and configure payment gateways',
      component: <PaymentGateway />,
    },
    {
      id: 'notifications',
      title: 'Notifications',
      icon: <Bell size={24} />,
      description: 'Set up notification preferences',
      component: <AdminNotifications />,
    },
    {
      id: '2fa',
      title: '2FA Settings',
      icon: <Lock size={24} />,
      description: 'Enable or disable two-factor authentication',
      component: <TwoFactorAuth />,
    },
    {
      id: 'announcements',
      title: 'Announcements',
      icon: <Megaphone  size={24} />,
      description: 'Manage announcements for users',
      component: <Announcements />,
    },
    {
      id: 'live-chat',
      title: 'Live Chat',
      icon: <MessageCircle size={24} />,
      description: 'Configure live chat settings',
      component: <LiveChat />,
    },
    {
      id: 'database-backup',
      title: 'Database Backup',
      icon: <Database size={24} />,
      description: 'Manage database backup and restore',
      component: <DatabaseBackup />,
    },
  ];

  const handleCloseAdminSettingsModal = () => {
    toast.success('Closed settings modal');
    setSelectedSettingModal(null);
  };

  return (
    <div className="admin-settings-page-container">
      <h2 className="settings-title">Admin Settings</h2>
      <div className="settings-grid">
        {settingsOptions.map((option) => (
          <div
            key={option.id}
            className="settings-card"
            onClick={() => setSelectedSettingModal(option)}
          >
            <div className="settings-card-icon">{option.icon}</div>
            <div className="settings-card-content">
              <h3>{option.title}</h3>
              <p>{option.description}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {selectedSettingModal && (
        <div className="admin-settings-modal">
          <div className="admin-settings-modal-content">
            <button
              className="admin-settings-close-modal-btn"
              onClick={handleCloseAdminSettingsModal}
            >
              Close
            </button>
            <h3 className="modal-title">{selectedSettingModal.title}</h3>
            <div className="admin-settings-modal-body">
              {selectedSettingModal.component}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminSettings;
