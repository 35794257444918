import React from "react";
import AdminHeader from "./AdminHeader";
import AdminChatWidget from "./AdminChatWidget";
import { Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const AdminLayout = () => {
  const { currentUser } = useAuth();
  const location = useLocation();

  // Exclude the header and chat widget for specific routes
  const hideLayout = location.pathname === "/admin/status";

  if (!currentUser) {
    return null; // Don't render layout for non-authenticated users
  }

  return (
    <div className="admin-layout">
      {!hideLayout && <AdminHeader />}
      <main>
        {/* Pass context to child routes */}
        <Outlet context={{ userId: currentUser.uid }} />
      </main>
      {!hideLayout && <AdminChatWidget />}
    </div>
  );
};

export default AdminLayout;
