import React, { useEffect, useState } from 'react';
import { database, ref, update, remove, onValue } from '../../firebase';
import { toast } from 'react-hot-toast';
import './ManageProducts.css';

const ManageProducts = () => {
  const [products, setProducts] = useState([]);

  // Fetch products from Firebase
  useEffect(() => {
    const productsRef = ref(database, 'products');
    onValue(productsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setProducts(Object.values(data));
      }
    });
  }, []);

  // Apply discount logic
  const handleApplyDiscount = async (productId, discount) => {
    const productRef = ref(database, `products/${productId}`);
    const product = products.find((p) => p.id === productId);
    if (!product) return;

    const discountedPrice =
      discount && !isNaN(discount) ? (product.price * (100 - discount)) / 100 : null;

    try {
      await update(productRef, { discountedPrice });
      toast.success(discount ? 'Discount applied successfully!' : 'Discount removed!');
    } catch (error) {
      console.error('Error applying discount:', error);
      toast.error('Failed to apply discount.');
    }
  };

  // Toggle product availability
  const toggleAvailability = async (productId, currentStatus) => {
    const productRef = ref(database, `products/${productId}`);
    try {
      await update(productRef, { available: !currentStatus });
      toast.success(
        `Product marked as ${currentStatus ? 'unavailable' : 'available'}!`
      );
    } catch (error) {
      console.error('Error updating availability:', error);
      toast.error('Failed to update product availability.');
    }
  };

  // Delete product
  const handleDeleteProduct = async (productId) => {
    const productRef = ref(database, `products/${productId}`);
    try {
      await remove(productRef);
      toast.success('Product deleted successfully!');
    } catch (error) {
      console.error('Error deleting product:', error);
      toast.error('Failed to delete product.');
    }
  };

  // Format prices using Intl.NumberFormat
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
    }).format(price);
  };

  return (
    <div className="manage-products-container">
      <h2>Manage Products</h2>
      <table className="products-table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Product</th>
            <th>Price</th>
            <th>Discount</th>
            <th>Availability</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id}>
              {/* Product Image */}
              <td>
                <img
                  src={product.image || '/placeholder.png'}
                  alt={product.name}
                  className="product-image"
                />
              </td>

              {/* Product Name */}
              <td>{product.name}</td>

              {/* Product Price */}
              <td>
                {product.discountedPrice ? (
                  <>
                    <span style={{ textDecoration: 'line-through' }}>
                      {formatPrice(product.price)}
                    </span>{' '}
                    {formatPrice(product.discountedPrice)}
                  </>
                ) : (
                  formatPrice(product.price)
                )}
              </td>

              {/* Discount Dropdown */}
              <td>
                <select
                  value=""
                  onChange={(e) =>
                    handleApplyDiscount(product.id, parseInt(e.target.value) || null)
                  }
                >
                  <option value="">Select Discount</option>
                  {[5, 10, 15, 20, 25].map((d) => (
                    <option key={d} value={d}>
                      {d}%
                    </option>
                  ))}
                  <option value="0">Remove Discount</option>
                </select>
              </td>

              {/* Availability */}
              <td>{product.available ? 'Available' : 'Unavailable'}</td>

              {/* Actions */}
              <td>
                <button
                  className={`availability-btn ${
                    product.available ? 'available' : 'unavailable'
                  }`}
                  onClick={() => toggleAvailability(product.id, product.available)}
                >
                  {product.available ? 'Mark as Unavailable' : 'Mark as Available'}
                </button>
                <button
                  className="delete-btn"
                  onClick={() => handleDeleteProduct(product.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageProducts;
