import React from "react";
import { User, Phone, MapPin, Building, Globe, Mail } from "lucide-react";
import "./Details.css";

const Details = ({ formData, handleInputChange }) => {
  return (
    <div className="details-section">
      <h2 className="details-title">Contact Information</h2>
      <p className="details-subtitle">Please provide your details to proceed.</p>

      <div className="form-grid">
        {/* Full Name */}
        <div className="form-input">
          <User className="form-icon" />
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>

        {/* Phone Number */}
        <div className="form-input">
          <Phone className="form-icon" />
          <input
            type="text"
            name="phone"
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleInputChange}
          />
        </div>

        {/* Address */}
        <div className="form-input">
          <MapPin className="form-icon" />
          <input
            type="text"
            name="address"
            placeholder="Street Address"
            value={formData.address}
            onChange={handleInputChange}
          />
        </div>

        {/* City */}
        <div className="form-input">
          <Building className="form-icon" />
          <input
            type="text"
            name="city"
            placeholder="City"
            value={formData.city}
            onChange={handleInputChange}
          />
        </div>

        {/* State */}
        <div className="form-input">
          <Globe className="form-icon" />
          <input
            type="text"
            name="state"
            placeholder="State"
            value={formData.state}
            onChange={handleInputChange}
          />
        </div>

        {/* Email */}
        <div className="form-input">
          <Mail className="form-icon" />
          <input
            type="text"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Details;
