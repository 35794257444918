import React, { useEffect, useState } from 'react';
import { ref, onValue, update, database } from '../firebase';
import toast, { Toaster } from 'react-hot-toast';
import { Box, Clock, Truck, CheckCircle, AlertTriangle } from 'lucide-react';
import Modal from 'react-modal';
import './AdminOrders.css';

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filters, setFilters] = useState({ date: '', month: '', year: '' });
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const sections = [
    { label: 'Order Received', icon: <Box />, statusKey: 'order_received' },
    { label: 'Preparing Order', icon: <Clock />, statusKey: 'preparing' },
    { label: 'Packing Order', icon: <Box />, statusKey: 'packing' },
    { label: 'Out for Delivery', icon: <Truck />, statusKey: 'out_for_delivery' },
    { label: 'Delivered', icon: <CheckCircle />, statusKey: 'delivered' },
  ];

  // Fetch orders from Firebase
  useEffect(() => {
    const ordersRef = ref(database, 'orders');
    const unsubscribe = onValue(ordersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const formattedOrders = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
          createdAt: data[key].createdAt
            ? new Date(data[key].createdAt) // Parse ISO string
            : null,
        }));
        setOrders(formattedOrders);
        setFilteredOrders(formattedOrders);
      } else {
        setOrders([]);
        setFilteredOrders([]);
      }
      setLoading(false);
    });
  
    return () => unsubscribe();
  }, []);
  

  // Filter Orders by Date, Month, and Year
  useEffect(() => {
    let filtered = orders;

    if (filters.date) {
      filtered = filtered.filter((order) => {
        const orderDate = order.createdAt;
        return orderDate && orderDate.getDate() === parseInt(filters.date, 10);
      });
    }

    if (filters.month) {
      filtered = filtered.filter((order) => {
        const orderDate = order.createdAt;
        return orderDate && orderDate.getMonth() + 1 === parseInt(filters.month, 10);
      });
    }

    if (filters.year) {
      filtered = filtered.filter((order) => {
        const orderDate = order.createdAt;
        return orderDate && orderDate.getFullYear() === parseInt(filters.year, 10);
      });
    }

    setFilteredOrders(filtered);
  }, [filters, orders]);

  // Calculate Estimated Delivery Time (+2 days for simplicity)
  const calculateEstimatedDelivery = (orderDate) => {
    const parsedDate = new Date(orderDate);
    if (isNaN(parsedDate)) return 'N/A'; // Handle invalid date
    const estimatedTime = new Date(parsedDate.getTime() + 2 * 24 * 60 * 60 * 1000);
    return estimatedTime.toLocaleString();
  };

  // Handle Status Update
  const handleStatusUpdate = async (orderId, newStatus) => {
    const orderRef = ref(database, `orders/${orderId}`);
    const prevOrders = [...orders]; // Backup current state for rollback

    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === orderId ? { ...order, status: newStatus } : order
      )
    );

    try {
      await update(orderRef, { status: newStatus });
      toast.success('Order status updated successfully!', {
        icon: <CheckCircle size={20} />,
      });
    } catch (error) {
      console.error('Error updating order status:', error);
      setOrders(prevOrders); // Revert to previous state
      toast.error('Failed to update order status.', {
        icon: <AlertTriangle size={20} />,
      });
    }
  };

  // Check if the status can be updated (Locked after 24 hours of delivery)
  const isStatusLocked = (order) => {
    if (order.status === 'delivered') {
      const deliveryDate = order.createdAt;
      const now = new Date();
      return deliveryDate && now - deliveryDate > 24 * 60 * 60 * 1000; // 24 hours
    }
    return false;
  };

  // Handle opening the modal
  const openModal = (order) => {
    setSelectedOrder(order);
    setModalOpen(true);
  };

  // Handle closing the modal
  const closeModal = () => {
    setSelectedOrder(null);
    setModalOpen(false);
  };

  return (
    <div className="admin-orders">
      <Toaster position="top-right" reverseOrder={false} />
      <h2>Manage Orders</h2>

      {/* Filters */}
      <div className="filters">
        <label>Filter by:</label>
        <select
          value={filters.date}
          onChange={(e) => setFilters({ ...filters, date: e.target.value })}
        >
          <option value="">Select Date</option>
          {[...Array(31)].map((_, i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
        <select
          value={filters.month}
          onChange={(e) => setFilters({ ...filters, month: e.target.value })}
        >
          <option value="">Select Month</option>
          {[...Array(12)].map((_, i) => (
            <option key={i + 1} value={i + 1}>
              {new Date(0, i).toLocaleString('default', { month: 'long' })}
            </option>
          ))}
        </select>
        <select
          value={filters.year}
          onChange={(e) => setFilters({ ...filters, year: e.target.value })}
        >
          <option value="">Select Year</option>
          {[...Array(5)].map((_, i) => {
            const year = new Date().getFullYear() - i;
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </select>
      </div>

      {/* Orders Table */}
      {loading ? (
        <p className="loading-message">Loading orders...</p>
      ) : filteredOrders.length > 0 ? (
        <table className="orders-table">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Order Number</th>
              <th>Delivery Method</th>
              <th>Order Date</th>
              <th>Estimated Delivery</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
  {filteredOrders.map((order) => (
    <tr key={order.id}>
      <td>{order.customerName || 'Unknown Customer'}</td>
      <td>
        <button className="order-number-btn" onClick={() => openModal(order)}>
          {order.trackingNumber || 'N/A'}
        </button>
      </td>
      <td>{order.deliveryMethod || 'N/A'}</td>
      <td>
  {order.createdAt
    ? new Date(order.createdAt).toLocaleDateString() // Format as "MM/DD/YYYY"
    : 'N/A'}
</td>

      <td>
        {order.createdAt
          ? calculateEstimatedDelivery(order.createdAt)
          : 'N/A'}
      </td>
      <td>
        <select
          value={order.status || ''}
          onChange={(e) => handleStatusUpdate(order.id, e.target.value)}
          disabled={isStatusLocked(order)}
        >
          {sections.map((section) => (
            <option key={section.statusKey} value={section.statusKey}>
              {section.label}
            </option>
          ))}
        </select>
        {isStatusLocked(order) && <span className="locked-status">Locked</span>}
      </td>
    </tr>
  ))}
</tbody>

        </table>
      ) : (
        <p className="no-orders-message">No orders available at the moment.</p>
      )}

      {/* Order Modal */}
      {selectedOrder && (
        <Modal
          isOpen={modalOpen}
          onRequestClose={closeModal}
          contentLabel="Order Details"
          className="order-modal"
          overlayClassName="order-modal-overlay"
        >
          <h2>Order Details</h2>
          <p>
            <strong>Order Number:</strong> {selectedOrder.trackingNumber || 'N/A'}
          </p>
          <p>
            <strong>Customer Name:</strong> {selectedOrder.customerName || 'Unknown'}
          </p>
          <p>
            <strong>Delivery Method:</strong> {selectedOrder.deliveryMethod || 'N/A'}
          </p>
          <p>
            <strong>Order Date:</strong>{' '}
            {selectedOrder.createdAt
              ? new Date(selectedOrder.createdAt).toLocaleString()
              : 'N/A'}
          </p>
          <p>
            <strong>Estimated Delivery:</strong>{' '}
            {selectedOrder.createdAt
              ? calculateEstimatedDelivery(selectedOrder.createdAt)
              : 'N/A'}
          </p>
          <p>
            <strong>Status:</strong>{' '}
            {sections.find((s) => s.statusKey === selectedOrder.status)?.label || 'Unknown'}
          </p>
          <h3>Items:</h3>
          <ul>
            {selectedOrder.items?.map((item, index) => (
              <li key={index}>
                {item.name || 'Unnamed Item'} - Qty: {item.quantity || 0} - ₦
                {(item.price || 0).toFixed(2)}
              </li>
            ))}
          </ul>
          <button onClick={closeModal} className="close-modal-btn">
            Close
          </button>
        </Modal>
      )}
    </div>
  );
};

export default AdminOrders;
