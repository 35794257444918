import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext"; // Auth context
import { toast, Toaster } from "react-hot-toast"; // For notifications
import { User, Phone, Mail, Home, Lock, Loader2 } from "lucide-react"; // Icons and spinner
import { ref, update, onValue } from "firebase/database";
import { database } from "../firebase"; // Firebase setup
import "./UserSettings.css"; // Custom styling

const UserSettings = () => {
  const { currentUser, updateUserPassword } = useAuth(); // Auth context
  const [fullName, setFullName] = useState("");
  const [address, setAddress] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Fetch user data from Firebase Realtime Database
  useEffect(() => {
    if (currentUser && currentUser.uid) {
      const userRef = ref(database, `users/${currentUser.uid}`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setFullName(data.fullName || "");
          setAddress(data.address || "");
          setMobileNumber(data.phone || "");
          setEmail(data.email || "");
        }
      });
    }
  }, [currentUser]);

  // Save user profile changes
  const handleProfileSave = async (e) => {
    e.preventDefault();

    if (!fullName || !address || !mobileNumber || !email) {
      toast.error("Please fill in all fields!");
      return;
    }

    setIsLoading(true);

    try {
      const userRef = ref(database, `users/${currentUser.uid}`);
      await update(userRef, { fullName, address, phone: mobileNumber, email });

      toast.success("Profile updated successfully!");
    } catch (error) {
      toast.error(`Error updating profile: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  // Change user password
  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (!oldPassword || !newPassword || !confirmPassword) {
      toast.error("Please fill in all password fields!");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match!");
      return;
    }

    setIsLoading(true);

    try {
      await updateUserPassword(newPassword);
      toast.success("Password changed successfully!");
    } catch (error) {
      toast.error(`Error changing password: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="UserSettings-Container">
      <Toaster position="top-right" reverseOrder={false} />
      <h2>User Settings</h2>

      {/* User Information Form */}
      <form onSubmit={handleProfileSave} className="UserSettings-Form">
        <div className="UserSettings-FormGroup">
          <label>Full Name</label>
          <div className="UserSettings-InputWrapper">
            <User className="UserSettings-InputIcon" />
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="UserSettings-FormGroup">
          <label>Address</label>
          <div className="UserSettings-InputWrapper">
            <Home className="UserSettings-InputIcon" />
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="UserSettings-FormGroup">
          <label>Mobile Number</label>
          <div className="UserSettings-InputWrapper">
            <Phone className="UserSettings-InputIcon" />
            <input
              type="tel"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="UserSettings-FormGroup">
          <label>Email</label>
          <div className="UserSettings-InputWrapper">
            <Mail className="UserSettings-InputIcon" />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>

        <button type="submit" className="UserSettings-SaveBtn">
          {isLoading ? <Loader2 className="LoaderIcon" /> : "Save Changes"}
        </button>
      </form>

      {/* Change Password Form */}
      <form onSubmit={handlePasswordChange} className="UserSettings-Form">
        <h3>Change Password</h3>
        <div className="UserSettings-FormGroup">
          <label>Old Password</label>
          <div className="UserSettings-InputWrapper">
            <Lock className="UserSettings-InputIcon" />
            <input
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="UserSettings-FormGroup">
          <label>New Password</label>
          <div className="UserSettings-InputWrapper">
            <Lock className="UserSettings-InputIcon" />
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="UserSettings-FormGroup">
          <label>Confirm New Password</label>
          <div className="UserSettings-InputWrapper">
            <Lock className="UserSettings-InputIcon" />
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
        </div>

        <button type="submit" className="UserSettings-SaveBtn">
          {isLoading ? <Loader2 className="LoaderIcon" /> : "Change Password"}
        </button>
      </form>
    </div>
  );
};

export default UserSettings;
