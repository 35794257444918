import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Details from './checkout/Details';
import DeliveryPaymentContact from './checkout/DeliveryPaymentContact';
import OrderSummary from './checkout/OrderSummary';
import CheckoutWidget from './checkout/CheckoutWidget';
import { ArrowLeft, ArrowRight, ShoppingCart, Check } from 'lucide-react';
import toast, { Toaster } from 'react-hot-toast';
import { useMediaQuery } from 'react-responsive';
import './Checkout.css';

// Helper function to format prices
const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
  }).format(amount);
};

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1024px)' });

  const { cartItems = [], total = 0 } = location.state || {};

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState(() => {
    const savedForm = JSON.parse(localStorage.getItem('formData'));
    return savedForm || {
      name: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      email: '',
      deliveryMethod: 'Delivery',
      paymentMethod: 'Card',
      contactMethod: 'Phone Call',
      agreeToTerms: false,
    };
  });

  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validateForm = (isFinalStep = false) => {
    const missingFields = [];
    if (!formData.name) missingFields.push('Name');
    if (!formData.phone) missingFields.push('Phone Number');
    if (!formData.address) missingFields.push('Address');
    if (!formData.city) missingFields.push('City');
    if (!formData.state) missingFields.push('State');
  
    if (missingFields.length > 0) {
      toast.error(`Please fill in: ${missingFields.join(', ')}`);
      return false;
    }
  
    if (isFinalStep && !formData.agreeToTerms) {
      toast('Please review and agree to the terms and conditions.', {
      });
    }
  
    return true; // Allow user to proceed regardless of terms checkbox
  };
  

  const handleNext = () => {
    if (!validateForm()) return;
    setStep((prev) => prev + 1);
  };

  const handleBack = () => {
    if (step > 1) setStep((prev) => prev - 1);
  };

  const handleEditCart = () => {
    localStorage.setItem('formData', JSON.stringify(formData));
    navigate('/cart');
  };

  const handleSubmit = async () => {
    if (!validateForm(true)) return;
  
    toast('Placing your order...');
  
    try {
      // Save cartItems and total to localStorage
      localStorage.setItem('cart', JSON.stringify(cartItems));
      localStorage.setItem('total', JSON.stringify(total));
  
      const requestBody = {
        amount: total,
        email: formData.email || '',
        name: formData.name || '',
        deliveryMethod: formData.deliveryMethod || 'Delivery',
        items: cartItems, // Include cart items in the request
      };
  
      const response = await fetch(
        'https://firstfltb.com/api3/flutterwave/initialize-payment.php',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestBody),
        }
      );
  
      const data = await response.json();
  
      if (response.ok && data.status === 'success') {
        toast.success('Redirecting to payment gateway...');
        window.location.href = data.data.link;
      } else if (response.ok) {
        toast.error(data.message || 'Failed to initialize payment. Please try again.');
      } else {
        toast.error(`Error: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      toast.error('An unexpected error occurred. Please try again.');
    }
  };
  

  return (
    <div className="checkout-container">
      <Toaster position="top-right" reverseOrder={false} />
      <div className={`checkout-main ${isLargeScreen ? 'with-sidebar' : ''}`}>
        {step === 1 && (
          <Details
            formData={formData}
            handleInputChange={handleInputChange}
            handleNext={handleNext}
          />
        )}
        {step === 2 && (
          <DeliveryPaymentContact
            formData={formData}
            handleInputChange={handleInputChange}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        )}
        {isLargeScreen && (
          <div className="order-summary-sidebar">
            <OrderSummary
              cartItems={cartItems}
              total={total}
              formData={formData}
              handleInputChange={handleInputChange}
            />
            <div className="sidebar-navigation">
              {step > 1 && (
                <button className="icon-btn" onClick={handleBack}>
                  <ArrowLeft size={20} />
                  <span>Prev</span>
                </button>
              )}
              {step < 2 ? (
                <button className="icon-btn" onClick={handleNext}>
                  <ArrowRight size={20} />
                  <span>Next</span>
                </button>
              ) : (
                <button className="icon-btn" onClick={handleSubmit}>
                  <Check size={20} />
                  <span>Place Order ({formatCurrency(total)})</span>
                </button>
              )}
              <button className="icon-btn" onClick={handleEditCart}>
                <ShoppingCart size={20} />
                <span>Edit Cart</span>
              </button>
            </div>
          </div>
        )}
      </div>

      {isSmallScreen && step === 3 && (
        <div className="mobile-summary">
          <OrderSummary
            cartItems={cartItems}
            total={total}
            formData={formData}
            handleInputChange={handleInputChange}
          />
        </div>
      )}

      {isSmallScreen && (
        <div style={{ marginBottom: '80px' }}>
          <CheckoutWidget
            step={step}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
          />
        </div>
      )}
    </div>
  );
};

export default Checkout;
