import React, { useState, useEffect, useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  FaHome,
  FaBoxOpen,
  FaTags,
  FaUserShield,
  FaMoneyCheckAlt,
  FaCog,
  FaSignOutAlt,
  FaStar,
  FaChartLine,
  FaClipboardList,
  FaTasks,
  FaBars,
  FaTimes,
  FaLock,
} from "react-icons/fa";
import { getDoc, doc } from "firebase/firestore";
import { ref, onValue, set } from "firebase/database"; // Firebase Realtime Database
import { firestore, database } from "../firebase";
import { useAuth } from "../context/AuthContext";
import toast, { Toaster } from "react-hot-toast";
import { Loader } from "lucide-react";
import "./AdminHeader.css";

const AdminHeader = () => {
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const [loggingOut, setLoggingOut] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [counts, setCounts] = useState({ activities: 0, orders: 0, transactions: 0 });
  const [userRole, setUserRole] = useState(null);
  const notificationAudio = new Audio("/notification.mp3");

  // Function to play notification sound
  const playNotificationSound = useCallback(() => {
    notificationAudio.play().catch((err) => {
      console.error("Failed to play notification sound:", err);
    });
  }, [notificationAudio]);

  // Logout function
  const handleLogout = async () => {
    setLoggingOut(true);
    try {
      await logout();
      navigate("/admin/login");
    } catch (error) {
      console.error("Error during logout:", error.message);
    } finally {
      setLoggingOut(false);
    }
  };

  // Toggle menu visibility
  const toggleMenu = () => setMenuOpen(!menuOpen);

  // Show restricted access alert
  const handleRestrictedAccess = () => {
    toast.error("You need Admin privileges to access this section.");
  };

  // Reset notification count for a section and navigate
  const handleLinkClick = async (section, path) => {
    setMenuOpen(false); // Close the menu

    // Reset count in Firebase
    try {
      const sectionRef = ref(database, `adminCounts/${section}/count`);
      await set(sectionRef, 0);

      // Reset count locally
      setCounts((prevCounts) => ({
        ...prevCounts,
        [section]: 0,
      }));

      // Navigate to the path
      navigate(path);
    } catch (error) {
      console.error(`Error resetting count for ${section}:`, error);
    }
  };

  // Fetch user role from Firestore
  useEffect(() => {
    const fetchUserRole = async () => {
      if (currentUser && currentUser.uid) {
        try {
          const userDocRef = doc(firestore, "users", currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserRole(userData.role || null);
          } else {
            console.error("User document not found in Firestore.");
            setUserRole(null);
          }
        } catch (error) {
          console.error("Error fetching user role from Firestore:", error.message);
          setUserRole(null);
        }
      } else {
        console.warn("Current user or UID not found. Cannot fetch role.");
        setUserRole(null);
      }
    };

    fetchUserRole();
  }, [currentUser]);

  // Fetch notification counts and update counts state
  useEffect(() => {
    const activitiesRef = ref(database, "adminCounts/activities/count");
    const ordersRef = ref(database, "adminCounts/orders/count");
    const transactionsRef = ref(database, "adminCounts/transactions/count");

    // Listener for activities
    onValue(activitiesRef, (snapshot) => {
      const newCount = snapshot.val() || 0;
      setCounts((prevCounts) => {
        if (newCount > prevCounts.activities) playNotificationSound();
        return { ...prevCounts, activities: newCount };
      });
    });

    // Listener for orders
    onValue(ordersRef, (snapshot) => {
      const newCount = snapshot.val() || 0;
      setCounts((prevCounts) => {
        if (newCount > prevCounts.orders) playNotificationSound();
        return { ...prevCounts, orders: newCount };
      });
    });

    // Listener for transactions
    onValue(transactionsRef, (snapshot) => {
      const newCount = snapshot.val() || 0;
      setCounts((prevCounts) => {
        if (newCount > prevCounts.transactions) playNotificationSound();
        return { ...prevCounts, transactions: newCount };
      });
    });
  }, [playNotificationSound]);
  
  

  if (!currentUser) return null;

  const isAdmin = userRole === "admin";
  const isCashier = userRole === "cashier";
  const canAccessTransactions = isAdmin || isCashier;

  return (
    <header className="admin-header">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="admin-header-content">
        <div className="admin-header-left">
          <button
            className="hamburger-menu"
            onClick={toggleMenu}
            aria-label="Toggle Menu"
          >
            {menuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>

        <nav className={`admin-nav ${menuOpen ? "open" : ""}`}>
          <NavLink
            to="/admin/dashboard"
            className={`admin-nav-link ${!isAdmin ? "restricted" : ""}`}
            onClick={(e) => {
              if (!isAdmin) {
                e.preventDefault();
                handleRestrictedAccess();
              } else {
                handleLinkClick("dashboard", "/admin/dashboard");
              }
            }}
          >
            <FaHome /> Dashboard {!isAdmin && <FaLock />}
          </NavLink>

          <NavLink
            to="/admin/products"
            className={`admin-nav-link ${!isAdmin ? "restricted" : ""}`}
            onClick={(e) => {
              if (!isAdmin) {
                e.preventDefault();
                handleRestrictedAccess();
              } else {
                handleLinkClick("products", "/admin/products");
              }
            }}
          >
            <FaBoxOpen /> Products {!isAdmin && <FaLock />}
          </NavLink>

          <NavLink
            to="/admin/categories"
            className={`admin-nav-link ${!isAdmin ? "restricted" : ""}`}
            onClick={(e) => {
              if (!isAdmin) {
                e.preventDefault();
                handleRestrictedAccess();
              } else {
                handleLinkClick("categories", "/admin/categories");
              }
            }}
          >
            <FaTags /> Categories {!isAdmin && <FaLock />}
          </NavLink>

          <NavLink
            to="/admin/orders"
            className="admin-nav-link"
            onClick={() => handleLinkClick("orders", "/admin/orders")}
          >
            <FaClipboardList /> Orders{" "}
            {counts.orders > 0 && <span className="badge">{counts.orders}</span>}
          </NavLink>

          <NavLink
            to="/admin/users"
            className={`admin-nav-link ${!isAdmin ? "restricted" : ""}`}
            onClick={(e) => {
              if (!isAdmin) {
                e.preventDefault();
                handleRestrictedAccess();
              } else {
                handleLinkClick("users", "/admin/users");
              }
            }}
          >
            <FaUserShield /> Users {!isAdmin && <FaLock />}
          </NavLink>

          <NavLink
            to="/admin/transactions"
            className={`admin-nav-link ${!canAccessTransactions ? "restricted" : ""}`}
            onClick={(e) => {
              if (!canAccessTransactions) {
                e.preventDefault();
                handleRestrictedAccess();
              } else {
                handleLinkClick("transactions", "/admin/transactions");
              }
            }}
          >
            <FaMoneyCheckAlt /> Transactions {!canAccessTransactions && <FaLock />}
          </NavLink>

          <NavLink
            to="/admin/reviews"
            className={`admin-nav-link ${!isAdmin ? "restricted" : ""}`}
            onClick={(e) => {
              if (!isAdmin) {
                e.preventDefault();
                handleRestrictedAccess();
              } else {
                handleLinkClick("reviews", "/admin/reviews");
              }
            }}
          >
            <FaStar /> Reviews {!isAdmin && <FaLock />}
          </NavLink>

          <NavLink
            to="/admin/best-sellers"
            className={`admin-nav-link ${!isAdmin ? "restricted" : ""}`}
            onClick={(e) => {
              if (!isAdmin) {
                e.preventDefault();
                handleRestrictedAccess();
              } else {
                handleLinkClick("bestSellers", "/admin/best-sellers");
              }
            }}
          >
            <FaChartLine />Sellers {!isAdmin && <FaLock />}
          </NavLink>

          <NavLink
            to="/admin/activities"
            className={`admin-nav-link ${!isAdmin ? "restricted" : ""}`}
            onClick={(e) => {
              if (!isAdmin) {
                e.preventDefault();
                handleRestrictedAccess();
              } else {
                handleLinkClick("activities", "/admin/activities");
              }
            }}
          >
            <FaTasks /> Activities{" "}
            {counts.activities > 0 && (
              <span className="badge">{counts.activities}</span>
            )}
          </NavLink>

          <NavLink
            to="/admin/settings"
            className={`admin-nav-link ${!isAdmin ? "restricted" : ""}`}
            onClick={(e) => {
              if (!isAdmin) {
                e.preventDefault();
                handleRestrictedAccess();
              } else {
                handleLinkClick("settings", "/admin/settings");
              }
            }}
          >
            <FaCog /> Settings {!isAdmin && <FaLock />}
          </NavLink>

          <button
            className="admin-logout-btn"
            onClick={handleLogout}
            disabled={loggingOut}
          >
            {loggingOut ? (
              <>
                <Loader className="loading-icon" /> Logging out...
              </>
            ) : (
              <>
                <FaSignOutAlt /> Logout
              </>
            )}
          </button>
        </nav>
      </div>
    </header>
  );
};

export default AdminHeader;
