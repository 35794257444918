import React, { useState } from 'react';
import AddProduct from './AdminProducts/AddProduct';
import ManageProducts from './AdminProducts/ManageProducts';
import './AdminProducts.css';
import { PlusCircle, Edit3 } from 'lucide-react';

const AdminProducts = ({ categories }) => {
  const [activeTab, setActiveTab] = useState('addProduct');

  return (
    <div className="admin-products-container">
      <div className="admin-tabs">
        <button
          className={`admin-tab ${activeTab === 'addProduct' ? 'active' : ''}`}
          onClick={() => setActiveTab('addProduct')}
        >
          <PlusCircle size={18} />
          Add Product
        </button>
        <button
          className={`admin-tab ${activeTab === 'manageProducts' ? 'active' : ''}`}
          onClick={() => setActiveTab('manageProducts')}
        >
          <Edit3 size={18} />
          Manage Products
        </button>
      </div>

      <div className="tab-content">
        {activeTab === 'addProduct' && <AddProduct categories={categories} />}
        {activeTab === 'manageProducts' && <ManageProducts />}
      </div>
    </div>
  );
};

export default AdminProducts;
