const DB_NAME = "KhadzBurgerDB";
const CART_STORE = "cartStore";
const IMAGE_STORE = "imageCache";
const DB_VERSION = 1;

// Open IndexedDB
const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);
    
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(CART_STORE)) {
        db.createObjectStore(CART_STORE);
      }
      if (!db.objectStoreNames.contains(IMAGE_STORE)) {
        db.createObjectStore(IMAGE_STORE);
      }
    };

    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject("[IndexedDB] Error opening DB.");
  });
};

// **Save Cart to IndexedDB**
export const saveCartToIndexedDB = async (cart) => {
  try {
    const db = await openDB();
    const transaction = db.transaction(CART_STORE, "readwrite");
    const store = transaction.objectStore(CART_STORE);
    store.put(cart, "userCart");
    console.log("[IndexedDB] Cart stored successfully.");
  } catch (error) {
    console.error("[IndexedDB] Failed to store cart:", error);
  }
};

// **Retrieve Cart from IndexedDB**
export const getCartFromIndexedDB = async () => {
  return new Promise(async (resolve) => {
    try {
      const db = await openDB();
      const transaction = db.transaction(CART_STORE, "readonly");
      const store = transaction.objectStore(CART_STORE);
      const request = store.get("userCart");

      request.onsuccess = () => {
        if (request.result) {
          console.log("[IndexedDB] Loaded cart from cache:", request.result);
          resolve(request.result);
        } else {
          console.warn("[IndexedDB] No cached cart found.");
          resolve({});
        }
      };

      request.onerror = () => {
        console.error("[IndexedDB] Error retrieving cart.");
        resolve({});
      };
    } catch (error) {
      console.error("[IndexedDB] Failed to retrieve cart:", error);
      resolve({});
    }
  });
};

// **Store Image in IndexedDB**
export const storeImageInIndexedDB = async (url, blob) => {
  try {
    const db = await openDB();
    const transaction = db.transaction(IMAGE_STORE, "readwrite");
    const store = transaction.objectStore(IMAGE_STORE);
    store.put(blob, url);
    console.log(`[IndexedDB] Cached image: ${url}`);
  } catch (error) {
    console.error("[IndexedDB] Error storing image:", error);
  }
};

// **Retrieve Image from IndexedDB**
export const getImageFromIndexedDB = async (url) => {
  return new Promise(async (resolve) => {
    try {
      const db = await openDB();
      const transaction = db.transaction(IMAGE_STORE, "readonly");
      const store = transaction.objectStore(IMAGE_STORE);
      const request = store.get(url);

      request.onsuccess = () => {
        if (request.result) {
          console.log(`[IndexedDB] Loaded from cache: ${url}`);
          resolve(URL.createObjectURL(request.result));
        } else {
          resolve(null);
        }
      };

      request.onerror = () => resolve(null);
    } catch (error) {
      resolve(null);
    }
  });
};

// **Fetch Image & Cache It**
export const fetchAndCacheImage = async (url, fallback = "/Assets/hero2.png") => {
  try {
    const cachedImage = await getImageFromIndexedDB(url);
    if (cachedImage) return cachedImage;

    const response = await fetch(url);
    if (!response.ok) throw new Error(`Failed to fetch image: ${url}`);

    const blob = await response.blob();
    await storeImageInIndexedDB(url, blob);
    return URL.createObjectURL(blob);
  } catch (error) {
    console.warn(`[IndexedDB] Failed to fetch image, using fallback: ${url}`);
    return fallback;
  }
};
