import React from "react";
import toast from "react-hot-toast";
import {
  Truck,
  Home,
  CreditCard,
  Banknote,
  Phone,
  MessageSquare,
  MessageCircle,
} from "lucide-react"; // Lucide icons
import "./DeliveryPaymentContact.css";

const DeliveryPaymentContact = ({ formData, handleInputChange, handleNext, handleBack }) => {
  const handleOptionClick = (name, value) => {
    handleInputChange({ target: { name, value } });

    // Show toast notification for user feedback
    toast.success(`Selected ${value} for ${name.replace(/Method/, "")}`);
  };

  return (
    <div className="delivery-payment-contact-section">
      {/* Delivery Method */}
      <section id="delivery-method">
        <h2>Delivery Method</h2>
        <p className="description">Choose how you'd like to receive your order:</p>
        <div className="selection-grid">
          <div
            className={`selection-option ${
              formData.deliveryMethod === "Pick Up" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("deliveryMethod", "Pick Up")}
          >
            <Truck className="selection-icon" />
            <span>Pick Up</span>
          </div>
          <div
            className={`selection-option ${
              formData.deliveryMethod === "Delivery" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("deliveryMethod", "Delivery")}
          >
            <Home className="selection-icon" />
            <span>Delivery</span>
          </div>
        </div>
      </section>

      {/* Payment Method */}
      <section id="payment-method">
        <h2>Payment Method</h2>
        <p className="description">Select your preferred payment option:</p>
        <div className="selection-grid">
          <div
            className={`selection-option ${
              formData.paymentMethod === "Card" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("paymentMethod", "Card")}
          >
            <CreditCard className="selection-icon" />
            <span>Card</span>
          </div>
          <div
            className={`selection-option ${
              formData.paymentMethod === "Bank Transfer" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("paymentMethod", "Bank Transfer")}
          >
            <Banknote className="selection-icon" />
            <span>Bank Transfer</span>
          </div>
        </div>
      </section>

      {/* Contact Method */}
      <section id="contact-method">
        <h2>Contact Method</h2>
        <p className="description">Choose how we can reach you:</p>
        <div className="selection-grid">
          <div
            className={`selection-option ${
              formData.contactMethod === "Phone Call" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("contactMethod", "Phone Call")}
          >
            <Phone className="selection-icon" />
            <span>Phone Call</span>
          </div>
          <div
            className={`selection-option ${
              formData.contactMethod === "SMS" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("contactMethod", "SMS")}
          >
            <MessageSquare className="selection-icon" />
            <span>SMS</span>
          </div>
          <div
            className={`selection-option ${
              formData.contactMethod === "Whatsapp" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("contactMethod", "Whatsapp")}
          >
            <MessageCircle className="selection-icon" />
            <span>Whatsapp</span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DeliveryPaymentContact;
