import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Truck, History, Ticket, LogOut, User } from "lucide-react"; // Lucide icons
import { toast, Toaster } from "react-hot-toast"; // Notifications
import { ref, onValue } from "firebase/database"; // Firebase functions
import { database } from "../firebase";
import "./UserHome.css";

const UserHome = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const [userName, setUserName] = useState(""); // For fetching user name
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");

  // Fetch user name from Firebase database
  useEffect(() => {
    if (currentUser?.uid) {
      const userRef = ref(database, `users/${currentUser.uid}`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setUserName(data.fullName || "N/A");
        }
      });
    }
  }, [currentUser]);

  // Get current date and time
  const getDateAndTime = () => {
    const now = new Date();
    setCurrentDate(now.toLocaleDateString());
    setCurrentTime(now.toLocaleTimeString());
  };

  useEffect(() => {
    getDateAndTime();
    const interval = setInterval(getDateAndTime, 60000); // Update every minute
    return () => clearInterval(interval);
  }, []);

  // Handle Logout
  const handleLogout = () => {
    logout();
    toast.success("Logged out successfully!");
    navigate("/"); // Redirect to home
  };

  const sections = [
    {
      title: "Track My Order",
      description: "View the status of your current orders.",
      icon: <Truck />,
      onClick: () => navigate("/track-order"),
    },
    {
      title: "Order History",
      description: "Check your previous order details.",
      icon: <History />,
      onClick: () => navigate("/order-history"),
    },
    {
      title: "Voucher",
      description: "View or use available vouchers and discounts.",
      icon: <Ticket />,
      onClick: () => navigate("/vouchers"),
    },
  ];

  return (
    <div className="UserHome-Container">
      <Toaster position="top-right" reverseOrder={false} />
      {/* Sticky Header */}
      <header className="UserHome-Header">
        <h1>User Dashboard</h1>
      </header>

      {/* User Info Card */}
      <div className="UserHome-Grid">
        <div className="UserHome-Card UserHome-InfoCard">
          <div className="UserHome-Icon">
            <User size={36} />
          </div>
          <div className="UserHome-Info">
            <p>
              <strong>Name:</strong> {userName}
            </p>
            <p>
              <strong>Date:</strong> {currentDate}
            </p>
            <p>
              <strong>Email:</strong> {currentUser?.email || "N/A"}
            </p>
            <p>
              <strong>Time:</strong> {currentTime}
            </p>
          </div>
        </div>

        {/* Section Cards */}
        {sections.map((section, index) => (
          <div
            key={index}
            className="UserHome-Card UserHome-SectionCard"
            onClick={section.onClick}
          >
            <div className="UserHome-SectionIcon">{section.icon}</div>
            <div className="UserHome-SectionDetails">
              <h3>{section.title}</h3>
              <p>{section.description}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Floating Logout Button */}
      <button className="UserHome-LogoutBtn" onClick={handleLogout}>
        <LogOut size={24} />
      </button>
    </div>
  );
};

export default UserHome;
