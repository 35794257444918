import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import axios from "axios";

const API_ENDPOINTS = {
  FETCH_2FA_STATUS: "https://firstfltb.com/api3/2fa/getTwoFactorStatus.php",
};

const ProtectedRoute = ({ children, roles = [], redirectPath = "/account" }) => {
  const { currentUser, loading, isTwoFactorVerified, setIsTwoFactorVerified } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const fetchTwoFactorStatus = async () => {
      if (!currentUser || localStorage.getItem("isTwoFactorVerified") === "true") {
        setIsTwoFactorVerified(true); // Sync state with localStorage
        return;
      }

      try {
        const { data } = await axios.post(API_ENDPOINTS.FETCH_2FA_STATUS, {
          userId: currentUser.uid,
        });


        if (data.twoFactorStatus?.active) {
          localStorage.setItem("isTwoFactorVerified", "false");
          setIsTwoFactorVerified(false);
        } else {
          localStorage.setItem("isTwoFactorVerified", "true");
          setIsTwoFactorVerified(true);
        }
      } catch (error) {
        console.error("Error fetching 2FA status:", error.message);
        localStorage.setItem("isTwoFactorVerified", "false");
        setIsTwoFactorVerified(false);
      }
    };

    fetchTwoFactorStatus();
  }, [currentUser, setIsTwoFactorVerified]);

  if (loading) {
    return <div>Loading...</div>;
  }



  if (!currentUser) {
    const isAdminRoute = location.pathname.startsWith("/admin");
    const redirect = isAdminRoute ? "/admin/login" : redirectPath;
    return <Navigate to={redirect} replace />;
  }

  if (!isTwoFactorVerified) {

    return location.pathname === "/admin/status" ? (
      children
    ) : (
      <Navigate to="/admin/status" replace />
    );
  }

  if (roles.length > 0 && !roles.includes(currentUser.role)) {
    const isAdminRoute = location.pathname.startsWith("/admin");
    const redirect = isAdminRoute ? "/admin/login" : "/dashboard";
    return <Navigate to={redirect} replace />;
  }

  return children;
};


export default ProtectedRoute;
