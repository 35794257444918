import React, { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { database, ref, set, onValue } from "../firebase";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./AdminCategories.css";

const AdminCategories = () => {
  const [categoryName, setCategoryName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [icons, setIcons] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addingCategory, setAddingCategory] = useState(false);

  // Fetch existing categories from Firebase
  useEffect(() => {
    const categoriesRef = ref(database, "categories");
    onValue(categoriesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setCategories(Object.values(data));
      }
    });
  }, []);

  const fetchIcons = async () => {
    if (!searchTerm) return;
    setLoading(true);
    try {
      const response = await axios.post(
        "https://firstfltb.com/api3/freepik.php",
        { query: searchTerm }
      );
      setIcons(response.data.icons || []); // Set icons or fallback to an empty array
    } catch (error) {
      console.error("Error fetching icons:", error);
      toast.error("Failed to fetch icons. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  

  const handleAddCategory = async () => {
    if (!categoryName || !selectedIcon) {
        toast.error("Please fill out all fields and select an icon.");
      return;
    }
  
    setAddingCategory(true);
    try {
      // Upload icon to Pinata
      const response = await axios.post(
        "https://firstfltb.com/api3/pinata.php",
        { iconUrl: selectedIcon.imageUrl }
      );
  
      if (!response.data.pinataUrl) {
        console.error("Pinata response missing 'pinataUrl':", response.data);
        toast.error("Failed to upload icon to Pinata. Please try again.");
        return;
      }
  
      const newCategory = {
        id: uuidv4(),
        name: categoryName,
        iconUrl: response.data.pinataUrl,
      };
  
      // Save category to Firebase
      const categoryRef = ref(database, `categories/${newCategory.id}`);
      await set(categoryRef, newCategory);
  
      toast.success("Category added successfully!");
      setCategoryName("");
      setSelectedIcon(null);
      setSearchTerm("");
      setIcons([]);
    } catch (error) {
      console.error("Error adding category:", error);
      toast.error("Failed to add category. Please try again.");
    } finally {
      setAddingCategory(false);
    }
  };
  

  return (
    <div className="admin-categories">
      <div className="admin-categories__split">
        {/* Left Panel */}
        <div className="admin-categories__add">
          <h2>Add New Category</h2>
          <input
            type="text"
            placeholder="Category Name"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            className="admin-categories__input"
          />
          <input
            type="text"
            placeholder="Search Icons"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="admin-categories__input"
          />
          <button
            onClick={fetchIcons}
            className="admin-categories__button"
            disabled={loading}
          >
            {loading ? "Searching..." : "Search Icons"}
          </button>
          <div className="admin-categories__icon-list">
            {icons.map((icon) => (
              <img
                key={icon.id}
                src={icon.imageUrl}
                alt={icon.title}
                onClick={() => setSelectedIcon(icon)}
                className={`admin-categories__icon ${
                  selectedIcon?.id === icon.id
                    ? "admin-categories__icon--selected"
                    : ""
                }`}
              />
            ))}
          </div>
          <button
            onClick={handleAddCategory}
            className="admin-categories__button"
            disabled={addingCategory}
          >
            {addingCategory ? "Adding..." : "Add Category"}
          </button>
        </div>

        {/* Right Panel */}
        <div className="admin-categories__view">
          <h2>Existing Categories</h2>
          <ul className="admin-categories__list">
            {categories.map((category) => (
              <li key={category.id} className="admin-categories__item">
                <img
                  src={category.iconUrl}
                  alt={category.name}
                  className="admin-categories__icon-view"
                />
                <span>{category.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminCategories;
