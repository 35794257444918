import React from 'react';
import { ArrowLeft, ArrowRight, Check, Loader2 } from 'lucide-react'; // Include Loader for better UX
import './CheckoutWidget.css';

const CheckoutWidget = ({ step, handleNext, handleBack, handleSubmit, isLoading }) => {
  return (
    <div className="checkout-widget">
      {/* Back Button */}
      {step > 1 && (
        <button className="widget-btn prev-btn" onClick={handleBack}>
          <ArrowLeft size={24} />
          <span>Prev</span>
        </button>
      )}
      {/* Next/Submit Button */}
      {step < 3 ? (
        <button className="widget-btn next-btn" onClick={handleNext}>
          <span>Next</span>
          <ArrowRight size={24} />
        </button>
      ) : (
        <button className="widget-btn submit-btn" onClick={handleSubmit} disabled={isLoading}>
          {isLoading ? <Loader2 className="loader-icon" size={24} /> : <Check size={24} />}
          <span>{isLoading ? 'Placing Order...' : 'Place Order'}</span>
        </button>
      )}
    </div>
  );
};

export default CheckoutWidget;
