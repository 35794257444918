import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { CheckCircle, AlertCircle, Shield, Key, Loader } from 'lucide-react';
import toast, { Toaster } from 'react-hot-toast'; // Import toast
import './TwoFactorAuth.css';

// Constants for API endpoints and 2FA types
const API_ENDPOINTS = {
    FETCH_STATUS: 'https://firstfltb.com/api3/2fa/getTwoFactorStatus.php',
    INITIATE_SETUP_EMAIL: 'https://firstfltb.com/api3/2fa/initiateTwoFactorEmail.php',
    INITIATE_SETUP_GOOGLE: 'https://firstfltb.com/api3/2fa/initiateTwoFactorGoogle.php',
    VERIFY_SETUP: 'https://firstfltb.com/api3/2fa/verifyTwoFactor.php',
    DISABLE_2FA: 'https://firstfltb.com/api3/2fa/removeTwoFactor.php',
  };
  

const TWO_FACTOR_TYPES = {
  GOOGLE: 'google',
  EMAIL: 'email',
};

const TwoFactorAuth = () => {
  const { currentUser } = useAuth();
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [twoFactorType, setTwoFactorType] = useState('');
  const [setupStep, setSetupStep] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Helper for API calls with error handling
  const apiRequest = async (url, payload) => {
    console.log("Making API Request:", { url, payload });
    try {
      const { data } = await axios.post(url, payload);
      console.log("API Response:", data);
      return data;
    } catch (error) {
      console.error("API Request Error:", error.response || error);
      throw error;
    }
  };
  

  // Fetch current 2FA status
  useEffect(() => {
    const fetchStatus = async () => {
      if (!currentUser) return;
      setLoading(true);
      setErrorMessage('');
      try {
        const data = await apiRequest(API_ENDPOINTS.FETCH_STATUS, {
          userId: currentUser.uid,
        });
  
        console.log('2FA Fetch Status:', data);
  
        // Extract twoFactorStatus from response
        const { twoFactorStatus } = data;
  
        if (twoFactorStatus && twoFactorStatus.active) {
          setTwoFactorEnabled(true);
          setTwoFactorType(twoFactorStatus.type || '');
          console.log('2FA Enabled:', true);
          console.log('2FA Type:', twoFactorStatus.type || '');
        } else {
          setTwoFactorEnabled(false);
          setTwoFactorType('');
          console.log('2FA Disabled');
        }
      } catch (error) {
        console.error('Error fetching 2FA status:', error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchStatus();
  }, [currentUser]);
  
  

// Initiate 2FA setup
const initiateTwoFactorSetup = async (type) => {
  if (!currentUser) return;
  setLoading(true);
  setErrorMessage('');
  
  console.log("2FA Setup Initiation Started"); // Log the initiation start

  try {
    const endpoint =
      type === TWO_FACTOR_TYPES.GOOGLE
        ? API_ENDPOINTS.INITIATE_SETUP_GOOGLE
        : API_ENDPOINTS.INITIATE_SETUP_EMAIL;

    console.log("Initiating 2FA Setup for Type:", type);
    console.log("Endpoint:", endpoint);

    const data = await apiRequest(endpoint, {
      userId: currentUser.uid,
      email: currentUser.email,
    });

    console.log("Backend Response:", data);

    if (type === TWO_FACTOR_TYPES.GOOGLE && data.qrCodeUrl) {
      console.log("Generated QR Code URL:", data.qrCodeUrl); // Log the QR code URL
      setQrCode(data.qrCodeUrl);
      console.log("QR Code State Updated:", data.qrCodeUrl); // Log when the state is updated
      toast.success('QR Code generated for Google Authenticator!');
    } else if (type === TWO_FACTOR_TYPES.EMAIL) {
      console.log("Email OTP Setup Response:", data); // Log email success response
      toast.success('Verification code sent to your email!');
    }

    setTwoFactorType(type);
    console.log("2FA Type State Updated:", type); // Log when type is updated

    setSetupStep(true);
    console.log("Setup Step State Updated: true"); // Log when the setup step is updated
  } catch (error) {
    console.error("Error initiating 2FA Setup:", error);
    toast.error('Failed to initiate 2FA setup. Please try again.');
  } finally {
    setLoading(false);
    console.log("Loading State Updated: false"); // Log when loading is complete
  }
};

  

  const verifyTwoFactorSetup = async () => {
    if (!verificationCode || !currentUser) {
      setErrorMessage('Please enter a verification code.');
      return;
    }
    setLoading(true);
    setErrorMessage('');
    try {
      const response = await axios.post(API_ENDPOINTS.VERIFY_SETUP, {
        userId: currentUser.uid, // User ID
        type: twoFactorType, // 2FA type: "google" or "email"
        token: verificationCode, // User-entered token
      });
  
      if (response.data.success) {
        toast.success('Two-factor authentication enabled!');
        setTwoFactorEnabled(true);
        setSetupStep(false);
        setVerificationCode('');
      } else {
        toast.error(response.data.error || 'Invalid verification code. Please try again.');
      }
    } catch (error) {
      toast.error('Failed to verify 2FA setup. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  

  // Disable 2FA
  const disableTwoFactor = async () => {
    if (!currentUser || !verificationCode) {
      setErrorMessage('Enter your current verification code to disable 2FA.');
      return;
    }
    setLoading(true);
    setErrorMessage('');
    try {
      const data = await apiRequest(API_ENDPOINTS.DISABLE_2FA, {
        userId: currentUser.uid,
        token: verificationCode,
      });
  
      // Check for backend response message
      if (data.message) {
        toast.success(data.message); // Show success toast with backend message
        setTwoFactorEnabled(false); // Reset 2FA state
        setTwoFactorType('');
        setVerificationCode('');
      } else if (data.error) {
        toast.error(data.error); // Show error toast with backend error
      } else {
        toast.error('Unknown response from server.');
      }
    } catch (error) {
      console.error('Error in disableTwoFactor:', error);
      toast.error('An error occurred while disabling 2FA. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="two-factor-auth-containe">
      <Toaster position="top-right" reverseOrder={false} /> {/* Add Toaster */}
      <h2>
        <Shield /> Two-Factor Authentication
      </h2>

      {loading && <p className="loading"><Loader /> Loading...</p>}

      {errorMessage && (
        <div className="error-message">
          <AlertCircle /> {errorMessage}
        </div>
      )}

      {!loading && !twoFactorEnabled && (
        <div>
          <p className="status-message">
            <AlertCircle /> Your account is <span className="not-secured">not secured</span>. Enable Two-Factor Authentication for added security.
          </p>
          <div className="setup-options">
            <button
              onClick={() => initiateTwoFactorSetup(TWO_FACTOR_TYPES.GOOGLE)}
              className="Two-setup-btn"
              disabled={loading}
            >
              <Key /> {loading ? 'Processing...' : 'Setup with Google Authenticator'}
            </button>
            <button
              onClick={() => initiateTwoFactorSetup(TWO_FACTOR_TYPES.EMAIL)}
              className="Two-setup-btn"
              disabled={loading}
            >
              <Key /> {loading ? 'Processing...' : 'Setup with Email'}
            </button>
          </div>
        </div>
      )}

      {twoFactorEnabled && (
        <div>
          <p className="status-message">
            <CheckCircle /> Your account is secured with{' '}
            {twoFactorType === TWO_FACTOR_TYPES.GOOGLE
              ? 'Google Authenticator'
              : 'Email'}.
          </p>
          <div className="remove-section">
            <p>To remove 2FA, enter your current verification token:</p>
            <input
              type="text"
              placeholder="Enter verification token"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <button
              onClick={disableTwoFactor}
              className="Two-remove-btn"
              disabled={loading}
            >
              <AlertCircle /> Disable 2FA
            </button>
          </div>
        </div>
      )}

{setupStep && (
  <div className="Two-setup-step">
    {twoFactorType === TWO_FACTOR_TYPES.GOOGLE && qrCode ? (
      <>
        <p>Scan this QR Code with Google Authenticator:</p>
        <img
          src={qrCode}
          alt="Google Authenticator QR Code"
          className="qr-code"
        />
      </>
    ) : (
      <p>Enter the code sent to your email to complete setup.</p>
    )}
    <input
      type="text"
      placeholder="Enter verification code"
      value={verificationCode}
      onChange={(e) => setVerificationCode(e.target.value)}
    />
    <button
      onClick={verifyTwoFactorSetup}
      className="Two-verify-btn"
      disabled={loading}
    >
      <CheckCircle /> Verify Code
    </button>
  </div>
)}

    </div>
  );
};

export default TwoFactorAuth;
