import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase'; // Ensure correct Firebase imports
import toast, { Toaster } from 'react-hot-toast';
import './AdminCreateAccount.css'; // Optional styling

const AdminCreateAccount = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCreateAccount = async (e) => {
    e.preventDefault();

    if (!email || !password || !role || !name) {
      toast.error('Please fill in all fields.');
      return;
    }

    setLoading(true);

    try {
      // Create user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Add user details to Firestore
      const userDocRef = doc(firestore, 'users', user.uid);
      await setDoc(userDocRef, {
        email,
        role,
        name,
        createdAt: new Date(),
        twoFactorEnabled: false, // Default 2FA to false
      });

      toast.success('Admin account created successfully!');
      // Reset form
      setEmail('');
      setPassword('');
      setRole('');
      setName('');
    } catch (error) {
      // Handle errors
      handleFirebaseErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFirebaseErrors = (error) => {
    switch (error.code) {
      case 'auth/email-already-in-use':
        toast.error('This email is already in use.');
        break;
      case 'auth/weak-password':
        toast.error('Password must be at least 6 characters.');
        break;
      case 'auth/invalid-email':
        toast.error('Invalid email format.');
        break;
      default:
        toast.error('Failed to create account. Please try again.');
        break;
    }
  };

  return (
    <div className="admin-create-account-container">
      <Toaster position="top-right" reverseOrder={false} />
      <h2>Create Admin or User Account</h2>
      <form onSubmit={handleCreateAccount}>
        <div className="admin-input-group">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            disabled={loading}
          />
        </div>
        <div className="admin-input-group">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={loading}
          />
        </div>
        <div className="admin-input-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={loading}
          />
        </div>
        <div className="admin-input-group">
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
            disabled={loading}
          >
            <option value="">Select Role</option>
            <option value="admin">Admin</option>
            <option value="cashier">Cashier</option>
          </select>
        </div>
        <button type="submit" disabled={loading} className="admin-create-btn">
          {loading ? 'Creating...' : 'Create Account'}
        </button>
      </form>
    </div>
  );
};

export default AdminCreateAccount;
